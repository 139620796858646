import {
  useState,
  useRef,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from 'react'
import { Steps, Modal } from 'antd'
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
} from 'firebase/firestore'
import { City, State } from 'country-state-city'
import { UserContext } from '../../../context'
import { useNavigate } from 'react-router-dom'

import {
  parseDataToBackend,
  parseIndustryOptions,
  parseDepartmentOptions,
} from './utils'
import { projectFireStore, db } from '../../../firebase/firebase'
import { Step1, Step2, Step3 } from './steps'

const Stepper = () => {
  const [current, setCurrent] = useState(0)
  const [projectInfo, setProjectInfo] = useState({
    0: { name: '', industries: null, desc: '' },
    1: { department: null, state: null, city: null, mode_name: 'EPC' },
    2: { initial_cost: null, actual_time: null, status: null },
  })
  const [industryOptions, setIndustryOptions] = useState([])
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const userInfo = useContext(UserContext)
  const navigate = useNavigate()
  const formRef = useRef()

  useEffect(() => {
    getIndustry()
  }, [])

  const stateOptions = useMemo(
    () =>
      State.getStatesOfCountry('IN').map((state) => ({
        label: state.name,
        value: state.name,
        isoCode: state.isoCode,
      })),
    []
  )

  const getCities = useCallback((stateCode) => {
    let cities = City.getCitiesOfState('IN', stateCode).map((city) => ({
      label: city.name,
      value: city.name,
    }))
    return cities
  }, [])

  const getIndustry = async () => {
    const snapshot = await projectFireStore.collection('industries').get()
    let industries = parseIndustryOptions(
      snapshot.docs.map((doc) => doc.data())
    )
    setIndustryOptions(industries)
  }

  const getDepartment = async (industryId) => {
    const q = query(
      collection(db, 'departments'),
      where('industry_id', 'array-contains', industryId)
    )

    const querySnapshot = await getDocs(q)
    let departments = querySnapshot.docs.map((doc) => doc.data())
    setDepartmentOptions(parseDepartmentOptions(departments))
  }

  const next = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const onSubmit = (val) => {
    setCurrent(current + 1)

    setProjectInfo((pre) => {
      return {
        ...pre,
        [current]: val,
      }
    })
  }

  const getUsersDetails = async () => {
    const q = query(collection(db, 'users'), where('role', 'in', [1, 2, 3]))

    const querySnapshot = await getDocs(q)
    let users = querySnapshot.docs
      .map((doc) => doc.data())
      .map((user) => user.user_id)

    return users
  }

  const onSubmitHandle = async (val) => {
    //CALL API TO
    let selectedDepartment = departmentOptions.find(
      (department) => department.value === projectInfo['1'].department
    )

    // GET ALL USERS ID WHO HAS ROLE OF 1,2,3
    let users = await getUsersDetails()

    let parsedData = parseDataToBackend({
      ...val,
      ...projectInfo['0'],
      ...projectInfo['1'],
      project_manager: [...new Set([userInfo.user_id, ...users])],
      sector: selectedDepartment.sector_name,
      department_id: selectedDepartment.department_id,
    })

    const newDocRef = doc(collection(db, 'projects')) // Add a new document with a generated id
    const docId = newDocRef.id //GET ID

    await setDoc(newDocRef, {
      ...parsedData,
      project_id: docId,
    })

    navigate('/admin/projects')
  }

  const steps = [
    {
      title: 'First',
      content: (
        <Step1
          onSubmit={onSubmit}
          initialValues={projectInfo['0']}
          formRef={formRef}
          industryOptions={industryOptions}
          onGetDepartment={getDepartment}
        />
      ),
    },
    {
      title: 'Second',
      content: (
        <Step2
          onSubmit={onSubmit}
          initialValues={projectInfo['1']}
          formRef={formRef}
          departmentOptions={departmentOptions}
          stateOptions={stateOptions}
          getCities={getCities}
        />
      ),
    },
    {
      title: 'Last',
      content: (
        <Step3
          onSubmit={onSubmitHandle}
          initialValues={projectInfo['2']}
          formRef={formRef}
        />
      ),
    },
  ]

  const showModal = () => {
    setIsModalOpen(true)
  }

  const onOk = () => {
    setIsModalOpen(false)
    navigate('/admin/projects')
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const items = useMemo(
    () =>
      steps.map((item) => ({
        key: item.title,
        title: item.title,
      })),
    [steps]
  )

  return (
    <>
      <div className='bg-[#F9F9F9] h-[5rem] w-full flex flex-row items-center px-10'>
        <div className='basis-1/3'>
          <h3 className='text-2xl cursor-pointer' onClick={showModal}>
            Cancel
          </h3>
        </div>
        <div className='basis-1/3'>
          <Steps current={current} items={items} />
        </div>
        <div className='basis-1/3'></div>
        {/* FORM ACCORDING TO STEP */}
      </div>

      <div className='px-20 py-20'>
        <div>{steps[current].content}</div>

        <div className='mt-16'>
          {current > 0 && (
            <button
              onClick={() => prev()}
              className='w-[11rem] h-[3rem] border-2 border-[#FFCC33] text-[#FFCC33] font-[600] rounded-full mr-8'
              type='button'
            >
              Previous
            </button>
          )}
          {current < steps.length - 1 && (
            <button
              className='w-[11rem] h-[3rem] bg-[#FFCC33] text-[#000] font-[600] rounded-full'
              onClick={() => next()}
              type='button'
            >
              Continue
            </button>
          )}
          {current === steps.length - 1 && (
            <button
              className='w-[11rem] h-[3rem] bg-[#FFCC33] text-[#000] font-[600] rounded-full'
              onClick={() => next()}
              type='button'
            >
              Add Project
            </button>
          )}
        </div>
      </div>

      {/* Modal Cancel */}
      <Modal
        title='Cancel'
        open={isModalOpen}
        onOk={onOk}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to cancel?</p>
      </Modal>
    </>
  )
}

export default Stepper
