import React, { useEffect, useState, useCallback, useContext } from 'react'
import { collection, query, where, getDocs } from 'firebase/firestore'

import { Card, Tags } from '../components/'
import { db, projectFireStore } from '../../../firebase/firebase'
import ProjectCard from './project-card'
import { checkSuperAdminRole, checkAdminRole, checkManagerRole } from 'utils'
import { UserContext } from '../../../context'

const Projects = () => {
  const [activeTag, setActiveTag] = useState('All Projects')
  const [projects, setProjects] = useState([])
  const [projectsCopy, setProjectsCopy] = useState([])
  const userInfo = useContext(UserContext)

  const isSuperAdmin = checkSuperAdminRole(userInfo.role)
  const isAdmin = checkAdminRole(userInfo.role)
  const isManager = checkManagerRole(userInfo.role)

  useEffect(() => {
    if (isSuperAdmin || isAdmin || isManager) {
      getProjects()
    } else {
      getProjectsByRole()
    }
  }, [])

  const getProjects = async () => {
    const snapshot = await projectFireStore.collection('projects').get()
    let projects = snapshot.docs.map((doc) => doc.data())
    setProjects(projects)
    setProjectsCopy(projects)
  }

  const getProjectsByRole = async () => {
    const q = query(
      collection(db, 'projects'),
      where('project_manager', 'array-contains', userInfo.user_id)
    )

    const querySnapshot = await getDocs(q)
    let projects = querySnapshot.docs.map((doc) => doc.data())
    setProjects(projects)
    setProjectsCopy(projects)
  }

  const onClickTag = useCallback(
    (tag) => {
      setActiveTag(tag)
      switch (tag) {
        case 'All Projects':
          setProjects(projectsCopy)
          break
        case 'In Progress Projects':
          setProjects(
            projectsCopy.filter((project) => project.status === 'In Progress')
          )
          break
        case 'Completed Projects':
          setProjects(
            projectsCopy.filter((project) => project.status === 'Completed')
          )
          break
        case 'Awarded Projects':
          setProjects(
            projectsCopy.filter((project) => project.status === 'Awarded')
          )
          break
        default:
          setProjects(projectsCopy)
          break
      }
    },
    [projectsCopy]
  )

  let addButton = (
    <div className='mt-12'>
      <Card
        label='Add project'
        bgColor='#F4F4FE'
        showIcon={true}
        labelColor='#000'
        link='/admin/projects/add'
      />
    </div>
  )

  let tags = (
    <div className='mt-12 mb-[2rem]'>
      <Tags
        tags={[
          'All Projects',
          'In Progress Projects',
          'Completed Projects',
          'Awarded Projects',
        ]}
        activeTag={activeTag}
        onClickTag={onClickTag}
      />
    </div>
  )

  const showBtnHandle = () => {
    if (isSuperAdmin || isAdmin) return addButton

    return <></>
  }

  const showTagHandle = () => {
    if (isSuperAdmin || isAdmin || isManager) return tags

    return <></>
  }

  return (
    <div>
      <h3 className='text-[56px] text-[#000] font-[600]'>RCL Projects</h3>
      {showBtnHandle()}

      {/* TAGS */}
      {showTagHandle()}
      <div className='flex flex-col gap-y-8'>
        {/* PROJECTS */}
        {projects.map((project) => (
          <ProjectCard
            key={project.project_id}
            project={project}
            id={project.project_id}
          />
        ))}
      </div>
    </div>
  )
}

export default Projects
