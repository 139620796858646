import { message } from 'antd'
import {
  SUPER_ADMIN_OUTLET_ROUTES,
  SUPER_ADMIN_ROUTES,
  ADMIN_OUTLET_ROUTES,
  ADMIN_ROUTES,
  MANAGER_OUTLET_ROUTES,
  MANAGER_ROUTES,
  PROJECT_DIRECTOR_OUTLET_ROUTES,
  PROJECT_DIRECTOR_ROUTES,
  PROJECT_MANAGER_OUTLET_ROUTES,
  PROJECT_MANAGER_ROUTES,
  SITE_ENGINEER_OUTLET_ROUTES,
  SITE_ENGINEER_ROUTES,
  CAREER_MANAGER_OUTLET_ROUTES,
  CAREER_MANAGER_ROUTES,
} from './routes'

const showError = (msg) => {
  message.error(msg)
}

const showSuccess = (msg) => {
  message.success(msg)
}

const checkCareerRole = (roleNo) => {
  return Number(roleNo) === 10 ? true : false
}

const checkSuperAdminRole = (roleNo) => {
  return Number(roleNo) === 1 ? true : false
}

const checkAdminRole = (roleNo) => {
  return Number(roleNo) === 2 ? true : false
}

const checkManagerRole = (roleNo) => {
  return Number(roleNo) === 3 ? true : false
}

const checkProjectDirectorRole = (roleNo) => {
  return Number(roleNo) === 4 ? true : false
}

const checkProjectManagerRole = (roleNo) => {
  return Number(roleNo) === 5 ? true : false
}

const checkSiteEngineerRole = (roleNo) => {
  return Number(roleNo) === 6 ? true : false
}

const getRoutes = (
  isSuperAdmin,
  isAdmin,
  isManager,
  isProjectDirector,
  isProjectManager,
  isSiteEngineer,
  isCareerManager
) => {
  if (isSuperAdmin)
    return {
      outletRoutes: SUPER_ADMIN_OUTLET_ROUTES,
      routes: SUPER_ADMIN_ROUTES,
    }
  else if (isAdmin)
    return {
      outletRoutes: ADMIN_OUTLET_ROUTES,
      routes: ADMIN_ROUTES,
    }
  else if (isManager)
    return {
      outletRoutes: MANAGER_OUTLET_ROUTES,
      routes: MANAGER_ROUTES,
    }
  else if (isProjectDirector)
    return {
      outletRoutes: PROJECT_DIRECTOR_OUTLET_ROUTES,
      routes: PROJECT_DIRECTOR_ROUTES,
    }
  else if (isProjectManager)
    return {
      outletRoutes: PROJECT_MANAGER_OUTLET_ROUTES,
      routes: PROJECT_MANAGER_ROUTES,
    }
  else if (isSiteEngineer)
    return {
      outletRoutes: SITE_ENGINEER_OUTLET_ROUTES,
      routes: SITE_ENGINEER_ROUTES,
    }
  else if (isCareerManager)
    return {
      outletRoutes: CAREER_MANAGER_OUTLET_ROUTES,
      routes: CAREER_MANAGER_ROUTES,
    }
  else {
    return {
      outletRoutes: CAREER_MANAGER_OUTLET_ROUTES,
      routes: CAREER_MANAGER_ROUTES,
    }
  }
}

export {
  showError,
  showSuccess,
  checkCareerRole,
  checkSuperAdminRole,
  checkAdminRole,
  checkManagerRole,
  checkProjectDirectorRole,
  checkProjectManagerRole,
  checkSiteEngineerRole,
  getRoutes,
}
