import React from 'react'

import Stepper from './stepper'

const AdminAddProject = () => {
  return (
    <>
      <Stepper />
    </>
  )
}

export default AdminAddProject
