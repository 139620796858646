import { timeStamp } from '../../../firebase/firebase'

const PROJECT_STATUS_OPTIONS = [
  {
    value: 'Awarded',
    label: 'Awarded',
  },
  {
    value: 'In Progress',
    label: 'In Progress',
  },
  {
    value: 'Completed',
    label: 'Completed',
  },
]

export const parseIndustryOptions = (data) => {
  return data.map((item) => ({
    label: item.name,
    value: item.industry_id,
  }))
}

export const parseDepartmentOptions = (data) => {
  return data.map((item) => ({
    label: item.name,
    value: item.short_name,
    sector_name: item.sector_name,
    department_id: item.department_id,
  }))
}

export const parseDataToBackend = (data) => {
  delete data.stateCode

  // console.log('DATA', data)
  // console.log('Filter dtaa', filterData)
  return {
    ...data,
    created_at: timeStamp(),
    updated_cost: data.initial_cost,
    mode: data.mode_name === 'EPC' ? 0 : 1,
    department_color: '#FFF000', //STATIC
    is_active: true, //DEFAULT
    is_cost_updated: false, //DEFAULT
  }
}

export { PROJECT_STATUS_OPTIONS }
