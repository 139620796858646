import React, { useState } from 'react'
import { Modal, Spin } from 'antd'
import {
  DownloadOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

import useFireStore from '../../../hooks/useFireStore'
import useStorage from '../../../hooks/useStorage'
import { motion } from 'framer-motion'

const DocumentGrid = ({
  onModalHandle,
  title,
  subTitle,
  project_id,
  showDeleteBtn,
}) => {
  const [alert, setAlert] = useState(false)
  const [show, setShow] = useState(false)
  const [dataToDelete, setDataToDelete] = useState(null)
  const [removing, setRemoving] = useState(false)

  const { data: documents } = useFireStore('documents', project_id)
  const { deleteFile } = useStorage()
  console.log('documents--', documents)

  const onDeleteReq = (data) => {
    setShow(true)
    setDataToDelete(data)
  }

  const onDelectConirm = async () => {
    setRemoving(true)
    const resp = await deleteFile(
      dataToDelete.url,
      dataToDelete.id,
      'documents'
    )
    setRemoving(false)
    setShow(false)
  }

  const onHide = () => {
    setShow(false)
    setDataToDelete(null)
  }

  return (
    <>
      <Modal
        title={title || `Delete document`}
        visible={show}
        onOk={onDelectConirm}
        onCancel={onHide}
        okText={removing ? 'Deleting..' : 'Yes'}
        okButtonProps={{ disabled: removing }}
        cancelButtonProps={{ disabled: removing }}
        centered
      >
        {removing ? (
          <>
            Please Wait... <Spin />
          </>
        ) : (
          subTitle || 'Are you sure you want to remove this entry'
        )}
      </Modal>

      <div className='grid grid-cols-1 auto-rows-[110px] gap-4 bg-[#fff] mt-6'>
        {documents &&
          documents.map((document) => (
            <motion.div
              key={document.document_id}
              //   whileHover={{ opacity: 1 }}
              layout
              //   initial={{ opacity: 0 }}
              //   animate={{ opacity: 1 }}
              //   transition={{ delay: 1 }}
              className='flex flex-row justify-between items-center shadow-[4px_4px_20px_rgba(0,0,0,0.07)] px-6 rounded-2xl transition-all duration-100 hover:bg-[#FAFCFF] hover:border-2 border-[#E5EAF2]'
            >
              <div className='flex flex-row items-center'>
                <div className='bg-[#D1CAFF] w-[16px] h-[16px] rounded-lg'></div>
                <div className='ml-8'>
                  <h3 className='text-xl font-[500] text-[#000]'>
                    {document.title}
                  </h3>
                  <div className='flex flex-row gap-x-6 '>
                    {document.tags.map((tag, index) => (
                      <div
                        key={index}
                        className='bg-[#D1CAFF] px-2 py-2 rounded-lg font-[500] text-[16px]'
                      >
                        {tag.tag_name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='flex flex-row gap-x-8'>
                <a href={document.document_url} target='_blank'>
                  <DownloadOutlined
                    style={{
                      color: '#4D4D4D',
                      fontSize: '30px',
                      cursor: 'pointer',
                    }}
                  />
                </a>

                {showDeleteBtn && (
                  <>
                    <EditOutlined
                      style={{
                        color: '#4D4D4D',
                        fontSize: '30px',
                        cursor: 'pointer',
                      }}
                      onClick={onModalHandle(true, 'EDIT', document)}
                    />
                    <DeleteOutlined
                      style={{
                        color: '#4D4D4D',
                        fontSize: '30px',
                        cursor: 'pointer',
                      }}
                      onClick={() => onDeleteReq(document)}
                    />
                  </>
                )}
              </div>
            </motion.div>
          ))}
      </div>
    </>
  )
}
export default DocumentGrid
