import React from 'react'

const Chip = ({ label, icon }) => {
  return (
    <div className='bg-[#dbf7ff] flex flex-row items-center px-3 py-2 rounded-full border border-[#B4EFFF]'>
      {icon && icon}
      <h3 className='text-[18px] text-[#4D4D4D] font-[600] ml-2'>{label}</h3>
    </div>
  )
}

export default Chip
