import React, { useEffect } from 'react'

import { Progress } from 'antd'
import useStorage from '../../hooks/useStorage'

const ProgressBar = (props) => {
  const { file, setFile, type, info } = props

  const { progress, url } = useStorage(file, type, info)

  useEffect(() => {
    if (url) {
      setFile(null)
    }
  }, [url, setFile])

  return <Progress percent={progress} showInfo={false} />
}

export default ProgressBar
