import firebase from 'firebase/compat/app' //v9
import { signInWithEmailAndPassword, getAuth, signOut } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/firestore' //v9

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_FIREBASE_KEY,
  authDomain: 'raj-corporation-limited.firebaseapp.com',
  projectId: 'raj-corporation-limited',
  storageBucket: 'raj-corporation-limited.appspot.com',
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: 'G-5SCZB2PRVQ',
}

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

const projectStorage = firebase.storage()
const projectFireStore = firebase.firestore()
const timeStamp = firebase.firestore.FieldValue.serverTimestamp

//LOGIN METHOD
const logInWithEmailAndPassword = async (email, password) => {
  try {
    let resp = await signInWithEmailAndPassword(auth, email, password)
    console.log('RESP--', resp)
    return { status: true }
  } catch (err) {
    return { status: false, error: err.message }
  }
}

const logout = () => {
  signOut(auth)
}

export {
  auth,
  db,
  projectStorage,
  projectFireStore,
  timeStamp,
  logInWithEmailAndPassword,
  logout,
}
