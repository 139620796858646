import React, { useContext, useMemo, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'

import { useNavigate } from 'react-router-dom'
import { auth } from '../../firebase/firebase'
import { SideBar } from 'components'
import { UserContext } from '../../context'
import { getMenu } from './utils'
import {
  checkCareerRole,
  checkSuperAdminRole,
  checkAdminRole,
  checkManagerRole,
  checkProjectDirectorRole,
  checkProjectManagerRole,
  checkSiteEngineerRole,
} from 'utils'

export default () => {
  const [user, loading, error] = useAuthState(auth)
  const navigate = useNavigate()

  // console.log('USER', user, loading)
  useEffect(() => {
    if (loading) {
      return
    }
    if (!user) navigate('/')
  }, [user, loading])

  const userInfo = useContext(UserContext)
  const isSuperAdmin = checkSuperAdminRole(userInfo.role)
  const isAdmin = checkAdminRole(userInfo.role)
  const isManager = checkManagerRole(userInfo.role)
  const isProjectDirector = checkProjectDirectorRole(userInfo.role)
  const isProjectManager = checkProjectManagerRole(userInfo.role)
  const isSiteEngineer = checkSiteEngineerRole(userInfo.role)
  const isCareerManager = checkCareerRole(userInfo.role)

  const sideMenu = useMemo(
    () =>
      getMenu(
        isSuperAdmin,
        isAdmin,
        isManager,
        isProjectDirector,
        isProjectManager,
        isSiteEngineer,
        isCareerManager
      ),
    [userInfo]
  )

  return (
    <div>
      <SideBar menu={sideMenu}>
        <Outlet />
      </SideBar>
    </div>
  )
}
