import React, { useEffect, useState, useCallback } from 'react'
import { Drawer, Menu } from 'antd'
import { Link } from 'react-router-dom'

import './style.css'
import { useMediaQuery } from 'react-responsive'
import { logout } from '../../firebase/firebase'

const drawerWidth = 300

const activeSideBar = (menu) => {
  for (let i = 0; i < menu.length; i++) {
    if (
      (menu[i].to.startsWith(window.location.pathname) &&
        menu[i].to.endsWith(window.location.pathname)) ||
      (menu[i].nested || []).indexOf(window.location.pathname) > -1
    ) {
      return [`${i}`]
    }
  }
  return ['0']
}

const SideBar = ({ children, menu = [] }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 768px)',
  })

  const toggle = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  useEffect(() => {
    setIsDrawerOpen(isDesktopOrLaptop)
  }, [isDesktopOrLaptop])

  const renderMenuItem = useCallback(
    (item, index) => {
      return (
        <>
          <Menu.Item
            onClick={() => {
              !isDesktopOrLaptop && toggle()
            }}
            key={index}
            icon={item.icon ? <item.icon /> : null}
          >
            <Link to={item.to}>{item.label}</Link>
          </Menu.Item>
        </>
      )
    },

    [menu, isDesktopOrLaptop]
  )

  return (
    <div id='sidebar'>
      <div
        className='content-wrapper'
        style={{ marginLeft: isDesktopOrLaptop ? drawerWidth : 0 }}
      >
        <div className='px-20 py-14'>{children}</div>
      </div>
      <Drawer
        placement='left'
        closable={!isDesktopOrLaptop}
        mask={!isDesktopOrLaptop}
        visible={isDrawerOpen}
        onClose={toggle}
        key='left'
        autoFocus={false}
        bodyStyle={{ background: '#FAF7F2' }}
        contentWrapperStyle={{ boxShadow: 'none', background: '#FAF7F2' }}
        width='320px'
        className='bg-[#FAF7F2]'
        style={{ background: '#FAF7F2' }}
      >
        <div className='py-14 pr-8 relative h-[100%]'>
          <h3 className='text-3xl text-[#000] font-[600] pl-8'>
            Raj Corporation Limited
          </h3>

          <div className='mt-14'>
            <Menu
              defaultSelectedKeys={activeSideBar(menu)}
              mode='inline'
              className='bg-[#FAF7F2]'
            >
              {menu.map(renderMenuItem)}
            </Menu>
          </div>
          <div className='bg-[#fff] px-14 py-2 absolute bottom-[3.5rem] left-[2rem] rounded-2xl cursor-pointer'>
            <Link
              key='1'
              to='#'
              onClick={logout}
              className='text-[#000] text-xl font-[700]'
            >
              Logout
            </Link>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default SideBar
