import React from 'react'

import Tag from './tag'

const Tags = ({ tags = [], ...rest }) => {
  return (
    <div className='flex flex-row flex-wrap gap-6'>
      {tags.map((tag, index) => (
        <Tag key={index} tag={tag} {...rest} />
      ))}
    </div>
  )
}

export default Tags
