import React, { useCallback, useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getDoc,
  doc,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore'
import {
  EnvironmentOutlined,
  ClockCircleOutlined,
  Loading3QuartersOutlined,
  CopyrightOutlined,
  ArrowLeftOutlined,
  EditOutlined,
} from '@ant-design/icons'

import { db } from '../../../firebase/firebase'
import Chip from './chip'
import { ImageIcon, VideoIcon, DocIcon } from '../../../icons'
import EditModal from './edit-modal'
import {
  checkSuperAdminRole,
  checkAdminRole,
  checkProjectDirectorRole,
} from 'utils'
import { UserContext } from '../../../context'

const ProjectDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [projectDetail, setProjectDetail] = useState()
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState({
    images: 0,
    videos: 0,
    documents: 0,
  })
  const [show, setShow] = useState(false)
  const userInfo = useContext(UserContext)

  const isSuperAdmin = checkSuperAdminRole(userInfo.role)
  const isAdmin = checkAdminRole(userInfo.role)
  const isProjectDirector = checkProjectDirectorRole(userInfo.role)

  useEffect(() => {
    if (!id) return
    getProjectDetailById()
    getCount('images')
    getCount('videos')
    getCount('documents')

    // getUsersDetails()
  }, [id])

  const getCount = async (collectionName) => {
    const q = query(
      collection(db, collectionName),
      where('project_id', '==', id)
    )

    const querySnapshot = await getDocs(q)
    let docs = querySnapshot.docs.map((doc) => doc.data())

    setTotal((pre) => ({ ...pre, [collectionName]: docs.length }))
  }

  const getProjectDetailById = async () => {
    const docRef = doc(db, 'projects', id)

    try {
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        setProjectDetail(docSnap.data())
      } else {
        console.log('Document does not exist')
      }
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }

  const onModalHandle = useCallback(
    (val) => () => {
      setShow(val)
    },
    []
  )

  const onFinalSave = useCallback((data) => {
    setProjectDetail((pre) => ({ ...pre, ...data }))
  }, [])

  let editBtn = (
    <div
      onClick={onModalHandle(true)}
      className='bg-[#FAFCFF] px-3 py-1 border border-[#E5EAF2] rounded-2xl flex justify-center items-center cursor-pointer'
    >
      <EditOutlined />
      <h3 className='text-[1rem] font-[600] ml-2 text-[#000]'>Edit</h3>
    </div>
  )

  const showBtnHandle = () => {
    if (isSuperAdmin || isAdmin || isProjectDirector) return editBtn

    return <></>
  }

  if (loading) return <></>

  return (
    <>
      {/* Edit Modal */}
      {show && (
        <EditModal
          show={show}
          onModalHandle={onModalHandle}
          projectDetail={projectDetail}
          onFinalSave={onFinalSave}
        />
      )}

      {/* HEADER */}
      <div
        className='flex flex-row items-center cursor-pointer mb-4'
        onClick={() => navigate('/admin/projects')}
      >
        <ArrowLeftOutlined size={25} style={{ color: '#000' }} />
        <h3 className='text-xl font-[600] ml-2 text-[#000]'>Back</h3>
      </div>
      <div className='shadow-[0px_1px_8px_rgba(0,0,0,0.07)]'>
        <div className='flex flex-row px-4 py-6'>
          <div className='flex-1'>
            <h3 className='text-[40px] text-[#000] font-[600]'>
              {projectDetail.name}
            </h3>
            <p className='text-[24px] text-[#000] font-[500] mt-3 leading-none	'>
              {projectDetail.desc}
            </p>
            <div className='flex flex-row items-center mt-3'>
              <EnvironmentOutlined style={{ color: '#000000', fontSize: 20 }} />
              <h3 className='text-[24px] text-[#4D4D4D] font-[600] ml-2'>
                {projectDetail.state}
              </h3>
            </div>

            <div className='flex flex-row mt-4 gap-4'>
              <Chip
                icon={<CopyrightOutlined />}
                label={projectDetail.updated_cost + 'crores'}
              />
              <Chip
                icon={<ClockCircleOutlined />}
                label={projectDetail.actual_time + 'years'}
              />
              <Chip
                icon={<Loading3QuartersOutlined />}
                label={projectDetail.status}
              />
            </div>

            <div className='flex flex-row items-center mt-3'>
              <div className='w-[16px] h-[16px] rounded-[8px] bg-[#617AFF]'></div>
              <h3 className='text-[24px] text-[#000] font-[600] ml-2'>
                {projectDetail.department}
              </h3>
            </div>
          </div>
          <div>{showBtnHandle()}</div>
        </div>
      </div>

      {/* BODY */}
      <div className='mt-10'>
        <h3 className='text-[32px] text-[#000] font-[700] mb-3'>
          Project Infometrics
        </h3>

        <div className='flex flex-row gap-x-12 flex-wrap gap-y-8'>
          <SmallCard
            icon={ImageIcon}
            label='Images'
            count={total.images}
            bgColor='#ECFBFF'
            id={projectDetail.project_id}
          />
          <SmallCard
            icon={VideoIcon}
            label='Videos'
            count={total.videos}
            bgColor='#FFF2EC'
            id={projectDetail.project_id}
          />
          <LargeCard
            icon={DocIcon}
            label='Documents'
            count={total.documents}
            id={projectDetail.project_id}
          />
        </div>
      </div>
    </>
  )
}

export default ProjectDetail

const SmallCard = ({ icon, label, count, bgColor, id, disabled = false }) => {
  const navigate = useNavigate()
  return (
    <div
      className='bg-[#ECFBFF] flex flex-row items-center justify-evenly rounded-[44px] pr-10 flex-1 cursor-pointer'
      style={{ backgroundColor: bgColor }}
      onClick={() =>
        navigate(`/admin/project-view/${id}?view=${label.toString()}`)
      }
    >
      <img src={icon} />
      <div>
        <h3 className='text-[46px] text-[#000] font-[700] text-center'>
          {count}
        </h3>
        <h3 className='text-[30px] text-[#000] font-[500] mt-[-14px]'>
          {label}
        </h3>
      </div>
    </div>
  )
}

const LargeCard = ({ icon, label, count, id, disabled = false }) => {
  const navigate = useNavigate()

  return (
    <div
      onClick={() =>
        navigate(`/admin/project-view/${id}?view=${label.toString()}`)
      }
      className='bg-[#FFF9E9] flex flex-row items-center justify-around rounded-[44px] pr-10 w-[100%] cursor-pointer'
    >
      <img src={icon} />
      <div>
        <h3 className='text-[46px] text-[#000] font-[700] text-center'>
          {count}
        </h3>
        <h3 className='text-[30px] text-[#000] font-[500] mt-[-14px]'>
          {label}
        </h3>
      </div>
      <div></div>
    </div>
  )
}
