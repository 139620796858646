import React, { useCallback, useState } from 'react'
import { Modal, Input } from 'antd'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '../../../firebase/firebase'
import { showError, showSuccess } from '../../../utils'

const EditModal = ({ show, onModalHandle, jobDetail, onFinalSave }) => {
  console.log('jobDetail', jobDetail)
  const [val, setVal] = useState({
    designation_opening: jobDetail.designation_opening,
    description: jobDetail.description,
  })

  const handleChange = useCallback((e) => {
    setVal((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }))
  }, [])

  const onSave = () => {
    //CHECK VALIDATIONS
    if (!val.designation_opening)
      return showError('Designation opening is required!')
    if (!val.description) return showError('Description is required!')

    //CALL FIREBASE API
    const docRef = doc(db, 'careers', jobDetail.career_id)
    const data = {
      ...val,
    }

    updateDoc(docRef, data)
      .then((docRef) => {
        showSuccess('Career updated successfully!')
        onFinalSave(data)
        onModalHandle(false)()
      })
      .catch((error) => {
        console.log(error)
        showError(error || 'Something went wrong!')
      })
  }

  return (
    <div className='edit-modal'>
      <Modal
        title=''
        open={show}
        closable={false}
        maskClosable={false}
        footer={null}
        centered={true}
        style={{
          position: 'absolute',
          left: 72,
          right: 72,
          top: 72,
          bottom: 72,
          width: 'auto',
        }}
        className='rounded-none'
        wrapClassName='rounded-none'
        destroyOnClose={true}
        width='auto'
      >
        <div className='p-5'>
          {/* HEADER BUTTONS */}
          <div className='flex flex-row justify-between'>
            <h3
              className='text-[24px] text-[#000000] font-[500] cursor-pointer'
              onClick={onModalHandle(false)}
            >
              Cancel
            </h3>
            <div
              onClick={onSave}
              className='w-[8rem] h-[2.5rem] rounded-3xl bg-[#FFCC33] cursor-pointer flex justify-center items-center'
            >
              <h3 className='font-[500] text-[#000] text-xl text-center'>
                Save
              </h3>
            </div>
          </div>
          {/*  BODY */}
          <div className='bg-[#FAFCFF] border-2 border-[#E2E8F0] mt-8 p-10 rounded-2xl'>
            <div className='flex flex-row flex-wrap '>
              <div className='basis-1/2 flex flex-col gap-y-6 pr-6'>
                <div>
                  <h3 className='font-[600] text-[#000]'>Location</h3>
                  <h3 className='font-[400] text-[#9D9D9D]'>
                    {jobDetail.locations[0].state}
                  </h3>
                </div>
                <div>
                  <h3 className='font-[600] text-[#000]'>Education</h3>
                  <h3 className='font-[400] text-[#9D9D9D]'>
                    {jobDetail.educations[0].name}
                  </h3>
                </div>
                <div>
                  <h3 className='font-[600] text-[#000]'>Description</h3>
                  <Input
                    placeholder=''
                    type='text'
                    onChange={handleChange}
                    value={val['description']}
                    name='description'
                    className='w-[100%] bg-[#FAFCFF] text-[18px] pl-0 border-t-0 border-x-0 border-[#000000] rounded-none outline-none focus:outline-none focus:shadow-none focus:border-[#4096ff] focus:border-x-0 hover:border-[#4096ff] hover:border-x-0	'
                  />
                </div>
              </div>
              <div className='basis-1/2 flex flex-col gap-y-6 pr-6'>
                <div>
                  <h3 className='font-[600] text-[#000]'>Openings</h3>
                  <Input
                    placeholder=''
                    type='number'
                    min={1}
                    onChange={handleChange}
                    value={val['designation_opening']}
                    name='designation_opening'
                    className='w-[100%] bg-[#FAFCFF] text-[18px] pl-0 border-t-0 border-x-0 border-[#000000] rounded-none outline-none focus:outline-none focus:shadow-none focus:border-[#4096ff] focus:border-x-0 hover:border-[#4096ff] hover:border-x-0	'
                  />
                </div>
                <div>
                  <h3 className='font-[600] text-[#000]'>Experience</h3>
                  <h3 className='font-[400] text-[#9D9D9D]'>
                    {jobDetail.educations[0].experience}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default EditModal
