import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PlusCircleOutlined } from '@ant-design/icons'

const Card = ({
  label = 'Jobs',
  bgColor = 'red',
  link,
  showIcon = false,
  labelColor = '#fff',
}) => {
  const navigate = useNavigate()
  return (
    <div
      className={`w-[25rem] h-[12rem] px-2 py-4 flex flex-col justify-center items-center rounded-[25px] cursor-pointer`}
      style={{ background: bgColor }}
      onClick={() => navigate(link)}
    >
      {showIcon && (
        <PlusCircleOutlined
          className='mb-2'
          style={{ fontSize: '40px', color: '#000' }}
        />
      )}
      <h3
        className='text-2xl font-[600] text-center'
        style={{ color: labelColor }}
      >
        {label}
      </h3>
    </div>
  )
}

export default Card
