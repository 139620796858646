import React, { useCallback, useEffect, useState } from 'react'
import { Modal, Input, Radio } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import {
  doc,
  collection,
  setDoc,
  updateDoc,
  onSnapshot,
  query,
} from 'firebase/firestore'

import { db, timeStamp } from '../../../firebase/firebase'
import { showError, showSuccess } from '../../../utils'
import { ProgressBar } from 'components'

const FILE_TYPES = 'application/pdf'

const DocumentModal = ({
  show,
  onModalHandle,
  documentDetail,
  onFinalSave,
  mode = 'ADD',
  info,
}) => {
  const [val, setVal] = useState({
    document_url: documentDetail.document_url,
    title: mode === 'EDIT' ? documentDetail.title : '',
    status: mode === 'EDIT' ? documentDetail.status : 'Sent',
    color: mode === 'EDIT' ? documentDetail.color : '#FFCC33',
    file: null,
  })
  const [input, setInput] = useState('')
  const [tags, setTags] = useState(mode === 'EDIT' ? documentDetail.tags : [])
  const [isKeyReleased, setIsKeyReleased] = useState(false)
  const [showProgress, setShowProgress] = useState(false)
  const [tagsDB, setTagsDB] = useState([])
  const [suggestedTags, setSuggestedTags] = useState([])

  useEffect(() => {
    const q = query(collection(db, 'tags'))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const documents = []
      querySnapshot.forEach((doc) => {
        documents.push({ ...doc.data(), id: doc.id })
      })
      setTagsDB(documents)
    })

    return () => unsubscribe()
  }, [])

  const handleChange = useCallback((e) => {
    setVal((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }))
  }, [])

  const onFileSelect = useCallback((event) => {
    let selectedFile = event.target.files[0]

    if (selectedFile && FILE_TYPES.includes(selectedFile.type)) {
      setVal((pre) => ({
        ...pre,
        [event.target.name]: selectedFile,
      }))
    } else {
      showError('Please select a file (pdf)')
    }
  }, [])

  const onSave = () => {
    if (!val.title) return showError('Document Title is required!')

    //CALL FIREBASE API
    const docRef = doc(db, 'documents', documentDetail.document_id)
    const data = {
      title: val.title,
      status: val.status,
      tags: tags,
    }
    updateDoc(docRef, data)
      .then((docRef) => {
        showSuccess('Document updated successfully!')
        onFinalSave(data)
        onModalHandle(false)()
      })
      .catch((error) => {
        console.log(error)
        showError(error || 'Something went wrong!')
      })
  }

  const onUpload = () => {
    // CHECK VALIDATIONS
    if (!val.file) return showError('Document File is required!')
    if (!val.title) return showError('Document Title is required!')

    setShowProgress(true)
  }

  const onComplete = useCallback(() => {
    setShowProgress(false)
    onModalHandle(false)()
  }, [])

  const onKeyDown = async (e) => {
    const { key } = e
    const trimmedInput = input.trim().toLocaleLowerCase()

    if (
      key === 'Enter' &&
      trimmedInput.length &&
      !tags.some((tag) => tag.tag_name.toLocaleLowerCase() === trimmedInput)
    ) {
      e.preventDefault()

      setInput('')
      setSuggestedTags([])

      let alreadyExistTag = tagsDB.find(
        (tag) => tag.name.toLocaleLowerCase() === trimmedInput
      )

      const newDocRef = doc(collection(db, 'tags')) // Add a new document with a generated id
      const docId = newDocRef.id //GET ID
      if (!alreadyExistTag) {
        setTags((pre) => [
          ...pre,
          {
            tag_id: docId,
            tag_name: trimmedInput,
            tag_color: '#FFCC33',
          },
        ])

        //IF Tag name already exist in DB then do not create tag
        const createdAtTime = timeStamp()
        //CALL API TO SAVE TAG
        setDoc(newDocRef, {
          tag_id: docId,
          name: trimmedInput,
          created_at: createdAtTime,
          user_id: info.creator_id,
          tag_color: '#FFCC33',
        })
      } else {
        setTags((pre) => [
          ...pre,
          {
            tag_id: alreadyExistTag.tag_id,
            tag_name: alreadyExistTag.name,
            tag_color: alreadyExistTag.tag_color,
          },
        ])
      }
    }

    if (key === 'Backspace' && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags]
      const poppedTag = tagsCopy.pop()
      e.preventDefault()
      setTags(tagsCopy)
      setInput(poppedTag.tag_name)
    }

    setIsKeyReleased(false)
  }

  const onKeyUp = () => {
    setIsKeyReleased(true)
  }

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index))
  }

  const handleTagInput = (e) => {
    setInput(e.target.value)

    //FIRST FILTER THOSE TAGS WHO IS ALREADY CREATED THEN SEARCH INCLUDES ONE
    let filteredTags = tagsDB
      .filter((tagDB) => !tags.some((tag) => tag.tag_name === tagDB.name))
      .filter((tag) =>
        tag.name
          .toLocaleLowerCase()
          .includes(e.target.value.toLocaleLowerCase())
      )

    //AND SHOW 10 TAGS
    setSuggestedTags(filteredTags.slice(0, 10))
  }

  return (
    <div className='edit-modal'>
      <Modal
        title=''
        open={show}
        closable={false}
        maskClosable={false}
        footer={null}
        style={{ minWidth: 1200 }}
        className='rounded-none'
        wrapClassName='rounded-none edit-modal'
        destroyOnClose={true}
      >
        <div className=''>
          {/* HEADER BUTTONS */}
          <div className='flex flex-row justify-between items-center shadow-[0_4px_4px_rgba(0,0,0,0.07)] py-5 px-8'>
            <h3
              className='text-[24px] text-[#000000] font-[500] cursor-pointer'
              onClick={onModalHandle(false)}
            >
              Cancel
            </h3>
            <h3 className='text-[40px] text-[#000000] font-[600] cursor-pointer'>
              {mode === 'ADD' ? 'Add Document' : 'Edit Document'}
            </h3>
            <div
              onClick={mode === 'ADD' ? onUpload : onSave}
              className='w-[8rem] h-[2.5rem] rounded-3xl bg-[#FFCC33] cursor-pointer flex justify-center items-center'
            >
              <h3 className='font-[500] text-[#000] text-xl text-center'>
                {mode === 'ADD' ? 'Upload' : 'Save'}
              </h3>
            </div>
          </div>
          {/*  */}
          {showProgress && (
            <ProgressBar
              file={val.file}
              info={{ ...val, ...info, tags: tags }}
              setFile={onComplete}
              type='documents'
            />
          )}
          {/*  BODY */}
          <div className='mt-8 pb-8 px-8'>
            <div className='flex flex-col gap-y-6'>
              <div>
                <h3 className='font-[600] text-[#000] text-[24px]'>
                  Document File
                </h3>
                {mode === 'ADD' ? (
                  <div className='relative w-[40%] mt-1 rounded-[16px] border border-[#ACACAC] flex flex-row items-center py-4 cursor-pointer'>
                    <h3 className='font-[600] text-[#818181] text-[22px] pl-7'>
                      {val.file?.name ? (
                        <span className='text-[#000]'>
                          {val.file.name.slice(0, 20)}
                        </span>
                      ) : (
                        'Upload document file'
                      )}
                    </h3>
                    <input
                      type='file'
                      onChange={onFileSelect}
                      name='file'
                      className='inset-0 opacity-0 absolute cursor-pointer'
                      accept={FILE_TYPES}
                    />
                  </div>
                ) : (
                  <h3 className='font-[400] text-[#818181] text-[12px]'>
                    {val.document_url}
                  </h3>
                )}
              </div>

              <div>
                <h3 className='font-[600] text-[#000] text-[24px]'>
                  Document Title
                </h3>
                <Input
                  placeholder=''
                  type='text'
                  onChange={handleChange}
                  value={val['title']}
                  name='title'
                  className='w-[70%] text-[18px] pl-0 border-t-0 border-x-0 border-[#ACACAC] rounded-none outline-none focus:outline-none focus:shadow-none focus:border-[#4096ff] focus:border-x-0 hover:border-[#4096ff] hover:border-x-0'
                />
              </div>

              <div>
                <h3 className='font-[600] text-[#000] text-[24px]'>
                  Document Status
                </h3>
                <Radio.Group
                  onChange={handleChange}
                  value={val['status']}
                  className='mt-4 font-[600] text-[20px] radio-group'
                  buttonStyle='solid'
                  size='large'
                  name='status'
                >
                  <Radio value='Sent' className='text-[20px]'>
                    Sent
                  </Radio>
                  <Radio value='Received' className='text-[20px]'>
                    Received
                  </Radio>
                </Radio.Group>
              </div>

              <div>
                <h3 className='font-[600] text-[#000] text-[24px] mr-5'>
                  Add document tags ({tags?.length}/3)
                </h3>

                <div className='w-[60%] flex flex-row flex-wrap gap-4 py-1 border-b-2 border-[#ACACAC]'>
                  {tags.map((tag, index) => (
                    <div
                      className='bg-[#FFCC33] pl-5 pr-3 rounded-3xl flex flex-row items-center'
                      key={tag.tag_name}
                    >
                      <h3 className='font-[600] text-[#000] text-[20px]'>
                        {tag.tag_name}
                      </h3>
                      <CloseCircleOutlined
                        onClick={() => deleteTag(index)}
                        className='ml-3 cursor-pointer'
                        style={{ fontSize: '20px', color: '#fff' }}
                      />
                    </div>
                  ))}
                  {tags.length < 3 && (
                    <input
                      value={input}
                      placeholder='Enter a tag'
                      onKeyDown={onKeyDown}
                      onChange={handleTagInput}
                      onKeyUp={onKeyUp}
                      className='border-none outline-none text-[20px] text-[600]'
                    />
                  )}
                </div>
                {/* SUGGESTED TAGS */}
                {Boolean(input.length) && Boolean(suggestedTags.length) && (
                  <div className='flex flex-row flex-wrap gap-4 mt-4 w-[60%]'>
                    {suggestedTags.map((tag, index) => (
                      <div
                        className='bg-[#E4E4E4] px-5 rounded-3xl flex flex-row items-center cursor-pointer'
                        key={tag.name}
                        onClick={() => {
                          setTags((pre) => [
                            ...pre,
                            {
                              tag_id: tag.tag_id,
                              tag_name: tag.name,
                              tag_color: tag.tag_color,
                            },
                          ])
                          setInput('')
                        }}
                      >
                        <h3 className='font-[600] text-[#000] text-[20px]'>
                          {tag.name}
                        </h3>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default DocumentModal
