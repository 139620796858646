import React from 'react'
import {
  EnvironmentOutlined,
  ClockCircleOutlined,
  Loading3QuartersOutlined,
  CopyrightOutlined,
} from '@ant-design/icons'

import Chip from './chip'
import { useNavigate } from 'react-router-dom'

const ProjectCard = ({ project, id }) => {
  console.log('PROJECT', project)
  const navigate = useNavigate()
  return (
    <div
      onClick={() => id && navigate(`/admin/project-detail/${id}`)}
      className='bg-[#fff] rounded-[44px] p-8 border border-rclGrey transition-all duration-500 hover:shadow-[4px_4px_20px_rgba(0,0,0,0.07)] hover:bg-rclHoverBlueAlpha cursor-pointer'
    >
      <div className='flex flex-row items-center'>
        <div className='w-4 h-4 rounded-full bg-rclBlue'></div>
        <h3 className='text-sm md:text-base lg:text-xl font-semibold ml-2'>
          {project.department}
        </h3>
      </div>

      <h3 className='text-xl md:text-2xl lg:text-4xl text-rclBlack font-semibold mt-4'>
        {project.name}
      </h3>
      <p className='text-sm md:text-base lg:text-xl text-rclBlack font-medium mt-2 leading-none'>
        {project.desc}
      </p>

      <div className='flex flex-row items-center mt-3'>
        <EnvironmentOutlined style={{ color: '#3F41EC', fontSize: 20 }} />
        <h3 className='text-sm md:text-base lg:text-xl text-rclBlue font-medium ml-2'>
          {project.state}
        </h3>
      </div>

      <div className='flex flex-row mt-4 gap-4'>
        <Chip
          icon={<CopyrightOutlined />}
          label={project.updated_cost + ' crores'}
        />
        <Chip
          icon={<ClockCircleOutlined />}
          label={project.actual_time + ' years'}
        />
        <Chip icon={<Loading3QuartersOutlined />} label={project.status} />
      </div>
    </div>
  )
}

export default ProjectCard
