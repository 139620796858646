import {
  AdminDashBoard,
  AdminProjects,
  AdminAddProject,
  AdminJobs,
  AdminAddJob,
  AdminJobDetail,
  ProjectDetail,
  ProjectView,
} from '../container'

const SUPER_ADMIN_OUTLET_ROUTES = [
  {
    path: 'dashboard',
    element: () => <AdminDashBoard />,
  },
  {
    path: 'jobs',
    element: () => <AdminJobs />,
  },
  {
    path: 'projects',
    element: () => <AdminProjects />,
  },
  {
    path: 'project-detail/:id',
    element: () => <ProjectDetail />,
  },
  {
    path: 'project-view/:id',
    element: () => <ProjectView />,
  },
]

const SUPER_ADMIN_ROUTES = [
  {
    path: 'jobs/add',
    element: () => <AdminAddJob />,
  },
  {
    path: 'projects/add',
    element: () => <AdminAddProject />,
  },
  {
    path: 'job-detail/:id',
    element: () => <AdminJobDetail />,
  },
]

const ADMIN_OUTLET_ROUTES = [
  {
    path: 'dashboard',
    element: () => <AdminDashBoard />,
  },
  {
    path: 'jobs',
    element: () => <AdminJobs />,
  },
  {
    path: 'projects',
    element: () => <AdminProjects />,
  },
  {
    path: 'project-detail/:id',
    element: () => <ProjectDetail />,
  },

  {
    path: 'project-view/:id',
    element: () => <ProjectView />,
  },
]

const ADMIN_ROUTES = [
  {
    path: 'jobs/add',
    element: () => <AdminAddJob />,
  },
  {
    path: 'projects/add',
    element: () => <AdminAddProject />,
  },
  {
    path: 'job-detail/:id',
    element: () => <AdminJobDetail />,
  },
]

const MANAGER_OUTLET_ROUTES = [
  {
    path: 'dashboard',
    element: () => <AdminDashBoard />,
  },
  {
    path: 'jobs',
    element: () => <AdminJobs />,
  },
  {
    path: 'projects',
    element: () => <AdminProjects />,
  },
  {
    path: 'project-detail/:id',
    element: () => <ProjectDetail />,
  },

  {
    path: 'project-view/:id',
    element: () => <ProjectView />,
  },
]

const MANAGER_ROUTES = [
  {
    path: 'job-detail/:id',
    element: () => <AdminJobDetail />,
  },
]

const PROJECT_DIRECTOR_OUTLET_ROUTES = [
  {
    path: 'dashboard',
    element: () => <AdminDashBoard />,
  },
  {
    path: 'projects',
    element: () => <AdminProjects />,
  },
  {
    path: 'project-detail/:id',
    element: () => <ProjectDetail />,
  },

  {
    path: 'project-view/:id',
    element: () => <ProjectView />,
  },
]

const PROJECT_DIRECTOR_ROUTES = []

const PROJECT_MANAGER_OUTLET_ROUTES = [
  {
    path: 'dashboard',
    element: () => <AdminDashBoard />,
  },
  {
    path: 'projects',
    element: () => <AdminProjects />,
  },
  {
    path: 'project-detail/:id',
    element: () => <ProjectDetail />,
  },
  {
    path: 'project-view/:id',
    element: () => <ProjectView />,
  },
]

const PROJECT_MANAGER_ROUTES = []

const SITE_ENGINEER_OUTLET_ROUTES = [
  {
    path: 'dashboard',
    element: () => <AdminDashBoard />,
  },
  {
    path: 'projects',
    element: () => <AdminProjects />,
  },
  {
    path: 'project-detail/:id',
    element: () => <ProjectDetail />,
  },

  {
    path: 'project-view/:id',
    element: () => <ProjectView />,
  },
]

const SITE_ENGINEER_ROUTES = []

const CAREER_MANAGER_OUTLET_ROUTES = [
  {
    path: 'dashboard',
    element: () => <AdminDashBoard />,
  },
  {
    path: 'jobs',
    element: () => <AdminJobs />,
  },
]

const CAREER_MANAGER_ROUTES = [
  {
    path: 'job-detail/:id',
    element: () => <AdminJobDetail />,
  },
]

export {
  SUPER_ADMIN_OUTLET_ROUTES,
  SUPER_ADMIN_ROUTES,
  ADMIN_OUTLET_ROUTES,
  ADMIN_ROUTES,
  MANAGER_OUTLET_ROUTES,
  MANAGER_ROUTES,
  PROJECT_DIRECTOR_OUTLET_ROUTES,
  PROJECT_DIRECTOR_ROUTES,
  PROJECT_MANAGER_OUTLET_ROUTES,
  PROJECT_MANAGER_ROUTES,
  SITE_ENGINEER_OUTLET_ROUTES,
  SITE_ENGINEER_ROUTES,
  CAREER_MANAGER_OUTLET_ROUTES,
  CAREER_MANAGER_ROUTES,
}
