import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { logout } from '../../../../firebase/firebase'

const Header = ({
  showBack = false,
  label = '',
  backLink = '/admin/dashboard',
  showLogoutBtn = false,
}) => {
  const navigate = useNavigate()
  return (
    <div
      // className='w-full bg-[#3F41EC] h-[10rem] flex flex-row items-center justify-between px-14'
      className='w-full bg-[#F9F9F9] h-[5rem] flex flex-row items-center justify-between px-14'
    >
      <div>
        {showBack && (
          <div
            className='flex flex-row items-center cursor-pointer'
            onClick={() => navigate(backLink)}
          >
            <ArrowLeftOutlined size={25} style={{ color: '#000' }} />
            <h3 className='text-xl font-[600] ml-2 text-[#000]'>Back</h3>
          </div>
        )}
      </div>
      <div>
        <h3 className='text-3xl font-[600] text-[#000]'>{label}</h3>
      </div>
      {showLogoutBtn ? (
        <div
          className='text-xl text-[#fff] w-[10rem] h-[2.5rem] bg-[#000] flex items-center justify-center rounded-2xl cursor-pointer'
          onClick={logout}
        >
          Logout
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default Header
