import React, { useState, useCallback } from 'react'

import { ProgressBar } from 'components'
import DocumentGrid from './document-grid'
import DocumentModal from './document-modal'

const DocumentView = ({
  icon,
  title,
  onUpload,
  accept,
  file,
  info,
  projectId,
  error,
  setFile,
  type,
  bgColor = '#ECFBFF',
  showAddBtn,
  showDeleteBtn,
}) => {
  const [mode, setMode] = useState('ADD')
  const [show, setShow] = useState(false)
  const [documentDetail, setDocumentDetail] = useState({})

  const onModalHandle = useCallback(
    (val, mode = 'ADD', docDetail = {}) =>
      () => {
        setShow(val)
        if (mode) setMode(mode)
        if (docDetail) setDocumentDetail(docDetail)
      },
    []
  )

  const onFinalSave = () => {}

  let btn = (
    <div
      style={{ background: bgColor }}
      className='relative rounded-3xl flex flex-row justify-center items-center py-4 cursor-pointer'
      onClick={onModalHandle(true)}
    >
      {icon && icon}
      <h3 className='text-[32px] text-[#4D4D4D] font-[700] ml-3'>{title}</h3>
    </div>
  )

  return (
    <>
      {/* DOC Modal */}
      {show && (
        <DocumentModal
          show={show}
          mode={mode}
          onModalHandle={onModalHandle}
          documentDetail={documentDetail}
          onFinalSave={onFinalSave}
          info={info}
        />
      )}

      <div>
        {showAddBtn && btn}
        <div>
          <DocumentGrid
            project_id={projectId}
            onModalHandle={onModalHandle}
            showDeleteBtn={showDeleteBtn}
          />

          {error && <div className='error'>{error}</div>}
        </div>
      </div>
    </>
  )
}

export default DocumentView
