import { Formik, Form, FieldArray } from "formik";
import { Input, Select, Radio, List } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { DEGREE_OPTIONS } from "./utils";
import * as Yup from "yup";
import Heading from "./heading";
import { useState } from "react";

const Step1 = ({
	onSubmit,
	initialValues,
	formRef,
	clientOptions,
	departmentOptions,
	onDepartmentChange,
	roles,
  onRoleChange,
}) => {
	const [selectedRole, setSelectedRole] = useState(null);

	// Add custom styles
	const listStyle = {
		display: "flex", // Makes the list horizontal
		gap: "16px", // Adds horizontal margin between items
		marginTop: "16px", // Adds margin-top
	};

	const listItemStyle = {
		border: "1px solid #ACACAC",
		borderRadius: "9999px", // Full rounded corners
		padding: "10px 20px", // Adjust as needed
		cursor: "pointer",
	};

	return (
		<>
			<Heading label="Create a new Job" />
			<div className="mt-16">
				<Formik
					initialValues={initialValues}
          onSubmit={(values) => {
            if (!selectedRole) {
              // Handle the error when no role is selected
              alert('Please select a role'); // Replace with a more suitable error handling
              return;
            }
            onSubmit({ ...values, role: selectedRole }); // Include the selected role in the submission
          }}
          validationSchema={Yup.object({
            client: Yup.string().required("Required"),
            department: Yup.string().required("Required"),
            description: Yup.string().required("Required"),
          })}
          innerRef={formRef}
				>
					{(props) => (
						<form onSubmit={props.handleSubmit}>
							<div className="w-[30rem]" id="select-input">
								<Select
									className="w-[100%] h-[5rem] border-[#ACACAC]"
									placeholder="Choose Client"
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.label ?? "").toLowerCase().includes(input)
									}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? "")
											.toLowerCase()
											.localeCompare((optionB?.label ?? "").toLowerCase())
									}
									onChange={(value) => {
										props.setFieldValue("client", value);
									}}
									value={props.values.client}
									options={clientOptions}
								/>
							</div>
							<div className="w-[30rem] mt-10" id="select-input">
								<Select
									className="w-[100%] h-[5rem] border-[#ACACAC]"
									placeholder="Choose Department"
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.label ?? "").toLowerCase().includes(input)
									}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? "")
											.toLowerCase()
											.localeCompare((optionB?.label ?? "").toLowerCase())
									}
									onChange={(value) => {
										props.setFieldValue("department", value);
										onDepartmentChange(value);
									}}
									value={props.values.department}
									options={departmentOptions}
								/>
							</div>
							{/* Roles Radio Buttons */}
							{roles && roles.length > 0 && (
								<div
									style={{ display: "flex", gap: "16px", marginTop: "16px" }}
								>
									{roles.map((item, index) => (
										<div
											key={index}
											onClick={() => {
                        setSelectedRole(item);
                        onRoleChange(item); // Update role in Stepper's state
                      }}
											style={{
												border: "1px solid #ACACAC",
												borderRadius: "9999px", // Full rounded corners
												padding: "10px 20px", // Adjust as needed
												cursor: "pointer",
												backgroundColor:
													selectedRole === item ? "#4096ff" : "#fff", // Blue bg if selected
												color: selectedRole === item ? "#fff" : "#000", // White text if selected
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
										>
											{item}
										</div>
									))}
								</div>
							)}

							<div className="flex flex-col mt-10">
								<label className="font-[600] text-[24px] text-[#000]">
									Add a Job Description*
								</label>
								<Input
									placeholder=""
									type="text"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.description}
									name="description"
									className="mt-4 text-[18px] pl-0 border-t-0 border-x-0 w-[32rem] border-[#000000] rounded-none outline-none focus:outline-none focus:shadow-none focus:border-[#4096ff] focus:border-x-0 hover:border-[#4096ff] hover:border-x-0	"
								/>
							</div>

							<div className="flex flex-col mt-10">
								<label className="font-[600] text-[24px] text-[#000]">
									Fresher Allowed
								</label>
								<Radio.Group
									onChange={(e) =>
										props.setFieldValue("is_fresher_allowed", e.target.value)
									}
									value={props.values.is_fresher_allowed}
									className="mt-4 font-[600] text-[24px]"
									buttonStyle="solid"
									size="large"
								>
									<Radio value={false} className="text-[24px]">
										No
									</Radio>
									<Radio value={true} className="text-[24px]">
										Yes
									</Radio>
								</Radio.Group>
							</div>
						</form>
					)}
				</Formik>
			</div>
		</>
	);
};

const Step2 = ({
	onSubmit,
	initialValues,
	formRef,
	stateOptions,
	getCities,
}) => {
	return (
		<>
			<Heading label="Add Job Location & Details" />
			<div className="mt-16">
				<Formik
					initialValues={initialValues}
					onSubmit={onSubmit}
					validationSchema={Yup.object({
						state: Yup.string().required("Required"),
						city: Yup.string().required("Required"),
					})}
					innerRef={formRef}
				>
					{(props) => (
						<form onSubmit={props.handleSubmit}>
							<div className="flex flex-row">
								<div className="w-[30rem] mt-10" id="select-input">
									<Select
										className="w-[100%] h-[5rem] border-[#ACACAC]"
										placeholder="Select State"
										onChange={(value) => {
											console.log("VALUE", value);
											props.setFieldValue("state", value);
											props.setFieldValue("city", null);

											let stateCode = stateOptions.find(
												(state) => state.value === value
											)?.isoCode;
											console.log("STATE code", stateCode);
											props.setFieldValue("stateCode", stateCode);
										}}
										value={props.values.state}
										options={stateOptions}
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) =>
											(option?.label ?? "").toLowerCase().includes(input)
										}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? "")
												.toLowerCase()
												.localeCompare((optionB?.label ?? "").toLowerCase())
										}
									/>
								</div>
								<div className="w-[30rem] mt-10 ml-10" id="select-input">
									{props.values.stateCode && (
										<Select
											className="w-[100%] h-[5rem] border-[#ACACAC]"
											placeholder="Select City"
											onChange={(value) => {
												props.setFieldValue("city", value);
											}}
											value={props.values.city}
											options={getCities(
												props.values.stateCode ? props.values.stateCode : null
											)}
											showSearch
											optionFilterProp="children"
											filterOption={(input, option) =>
												(option?.label ?? "").toLowerCase().includes(input)
											}
											filterSort={(optionA, optionB) =>
												(optionA?.label ?? "")
													.toLowerCase()
													.localeCompare((optionB?.label ?? "").toLowerCase())
											}
										/>
									)}
								</div>
							</div>
						</form>
					)}
				</Formik>
			</div>
		</>
	);
};

const Step3 = ({ onSubmit, initialValues, formRef, isFresherAllowed }) => {
	console.log("isFresherAllowed====>", isFresherAllowed);
	return (
		<>
			<Heading label="Add Education Qualifications" />
			<div className="mt-16">
				<Formik
					initialValues={initialValues}
					onSubmit={onSubmit}
					innerRef={formRef}
					validationSchema={Yup.object().shape({
						education: Yup.array()
							.of(
								Yup.object().shape({
									name: Yup.string().required("Required"), // these constraints take precedence
									experience: Yup.string().required("Required"), // these constraints take precedence
								})
							)
							.required("Required"), // these constraints are shown if and only if inner constraints are satisfied
					})}
				>
					{(props) => (
						<Form>
							<FieldArray
								name="education"
								render={(arrayHelpers) => (
									<div>
										{props.values.education.map((item, index) => (
											<div className="flex flex-row gap-x-10 mb-10" key={index}>
												<div className="w-[35rem]" id="select-input">
													<Select
														className="w-[100%] h-[5rem] border-[#ACACAC]"
														placeholder="Select Degree"
														name={`education[${index}].name`}
														onChange={(value) => {
															props.setFieldValue(
																`education[${index}].name`,
																value
															);
														}}
														value={props.values.education[index].name}
														options={DEGREE_OPTIONS}
														showSearch
														optionFilterProp="children"
														filterOption={(input, option) =>
															(option?.label ?? "")
																.toLowerCase()
																.includes(input)
														}
														filterSort={(optionA, optionB) =>
															(optionA?.label ?? "")
																.toLowerCase()
																.localeCompare(
																	(optionB?.label ?? "").toLowerCase()
																)
														}
													/>
												</div>

												{!isFresherAllowed && (
													<div className="flex flex-row">
														<div className="w-[10rem] h-[5rem] text-[#000] bg-[#ECECEC] font-2xl font-[600] flex justify-center items-center rounded-tl-[16px]	rounded-bl-[16px]	border-[#ACACAC] border">
															Experience
														</div>
														<Input
															size="large"
															onChange={props.handleChange}
															onBlur={props.handleBlur}
															value={props.values.education[index].experience}
															type="text"
															name={`education[${index}].experience`}
															className="w-[15rem] rounded-none border-x-0"
														/>
														<div className="w-[10rem] h-[5rem] text-[#000] bg-[#ECECEC] font-2xl font-[600] flex justify-center items-center rounded-tr-[16px]	rounded-br-[16px] border-[#ACACAC] border">
															in Years
														</div>
													</div>
												)}

												{Boolean(index > 0) && (
													<button
														type="button"
														className="w-[6.5rem] h-[4.5rem] bg-[#ECECEC] rounded-[16px] border-[#ACACAC] border flex justify-center items-center"
														onClick={() => arrayHelpers.remove(index)}
													>
														<MinusOutlined
															style={{
																color: "#818181",
																fontSize: "20px",
															}}
														/>
													</button>
												)}
											</div>
										))}

										{Boolean(props.values.education.length < 3) &&
											!isFresherAllowed && (
												<button
													type="button"
													onClick={() =>
														arrayHelpers.push({ name: null, experience: "1" })
													}
													className="w-[6.5rem] h-[4.5rem] bg-[#ECECEC] rounded-[16px] border-[#ACACAC] border flex justify-center items-center"
												>
													<PlusOutlined
														style={{
															color: "#818181",
															fontSize: "20px",
														}}
													/>
												</button>
											)}
									</div>
								)}
							/>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
};

export { Step1, Step2, Step3 };
