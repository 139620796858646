import { Formik } from 'formik'
import { Input, Select, Radio } from 'antd'
import { PROJECT_STATUS_OPTIONS } from './utils'
import * as Yup from 'yup'
import Heading from './heading'

const Step1 = ({
  onSubmit,
  initialValues,
  formRef,
  industryOptions,
  onGetDepartment,
}) => {
  return (
    <>
      <Heading label='Let’s start with your Project Name and Sector' />
      <div className='mt-16'>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
            industries: Yup.string().required('Required'),
          })}
          innerRef={formRef}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <div className='flex'>
                <div className='flex flex-col'>
                  <label className='font-[600]'>Project Name*</label>
                  <Input
                    placeholder=''
                    type='text'
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.name}
                    name='name'
                    className='mt-4 text-[18px] pl-0 border-t-0 border-x-0 w-[32rem] border-[#000000] rounded-none outline-none focus:outline-none focus:shadow-none focus:border-[#4096ff] focus:border-x-0 hover:border-[#4096ff] hover:border-x-0	'
                  />
                </div>

                <div className='w-[30rem] ml-20' id='select-input'>
                  <Select
                    className='w-[100%] h-[5rem] border-[#ACACAC]'
                    placeholder='Choose industries'
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    onChange={(value) => {
                      props.setFieldValue('industries', value)
                      onGetDepartment(value) //CALL API EVERY TIME WHEN INDUSTRY CHANGE
                    }}
                    value={props.values.industries}
                    options={industryOptions}
                  />
                </div>
              </div>

              <div className='flex flex-col mt-10'>
                <label className='font-[600]'>Project Description*</label>
                <Input
                  placeholder=''
                  type='text'
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.desc}
                  name='desc'
                  className='mt-4 text-[18px] pl-0 border-t-0 border-x-0 w-[32rem] border-[#000000] rounded-none outline-none focus:outline-none focus:shadow-none focus:border-[#4096ff] focus:border-x-0 hover:border-[#4096ff] hover:border-x-0	'
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  )
}

const Step2 = ({
  onSubmit,
  initialValues,
  formRef,
  departmentOptions,
  stateOptions,
  getCities,
}) => {
  return (
    <>
      <Heading label='Add your Project Details' />
      <div className='mt-16'>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={Yup.object({
            department: Yup.string().required('Required'),
            state: Yup.string().required('Required'),
            city: Yup.string().required('Required'),
            mode_name: Yup.string().required('Required'),
          })}
          innerRef={formRef}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <div className='w-[30rem]' id='select-input'>
                <Select
                  className='w-[100%] h-[5rem] border-[#ACACAC]'
                  placeholder='Select Department'
                  onChange={(value) => {
                    props.setFieldValue('department', value)
                  }}
                  value={props.values.department}
                  options={departmentOptions}
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                />
              </div>

              <div className='flex flex-row'>
                <div className='w-[30rem] mt-10' id='select-input'>
                  <Select
                    className='w-[100%] h-[5rem] border-[#ACACAC]'
                    placeholder='Select State'
                    onChange={(value) => {
                      console.log('VALUE', value)
                      props.setFieldValue('state', value)
                      props.setFieldValue('city', null)

                      let stateCode = stateOptions.find(
                        (state) => state.value === value
                      )?.isoCode
                      console.log('STATE code', stateCode)
                      props.setFieldValue('stateCode', stateCode)
                    }}
                    value={props.values.state}
                    options={stateOptions}
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                  />
                </div>
                <div className='w-[30rem] mt-10 ml-10' id='select-input'>
                  {props.values.stateCode && (
                    <Select
                      className='w-[100%] h-[5rem] border-[#ACACAC]'
                      placeholder='Select City'
                      onChange={(value) => {
                        props.setFieldValue('city', value)
                      }}
                      value={props.values.city}
                      options={getCities(
                        props.values.stateCode ? props.values.stateCode : null
                      )}
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                    />
                  )}
                </div>
              </div>

              <div className='flex flex-col mt-10'>
                <label className='font-[500] text-[18px]'>Project Mode*</label>
                <Radio.Group
                  onChange={(e) =>
                    props.setFieldValue('mode_name', e.target.value)
                  }
                  value={props.values.mode_name}
                  className='mt-4 font-[600] text-[18px]'
                  buttonStyle='solid'
                  size='large'
                >
                  <Radio value='EPC' className='text-[18px]'>
                    EPC
                  </Radio>
                  <Radio value='HAM' className='text-[18px]'>
                    HAM
                  </Radio>
                </Radio.Group>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  )
}

const Step3 = ({ onSubmit, initialValues, formRef }) => {
  return (
    <>
      <Heading label='Project Status and Cost' />
      <div className='mt-16'>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          innerRef={formRef}
          validationSchema={Yup.object({
            initial_cost: Yup.string().required('Required'),
            actual_time: Yup.string().required('Required'),
            status: Yup.string().required('Required'),
          })}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <div className='flex flex-row gap-x-10'>
                <div className='flex flex-row'>
                  <div className='w-[10rem] h-[5rem] bg-[#ECECEC] font-2xl font-[600] flex justify-center items-center rounded-tl-[16px]	rounded-bl-[16px]	border-[#ACACAC] border'>
                    Project Cost
                  </div>
                  <Input
                    size='large'
                    min={1}
                    max={100000}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.initial_cost}
                    type='number'
                    name='initial_cost'
                    className='w-[15rem] rounded-none border-x-0'
                  />
                  <div className='w-[10rem] h-[5rem] bg-[#ECECEC] font-2xl font-[600] flex justify-center items-center rounded-tr-[16px]	rounded-br-[16px] border-[#ACACAC] border'>
                    in crores
                  </div>
                </div>

                <div className='flex flex-row'>
                  <div className='w-[10rem] h-[5rem] bg-[#ECECEC] font-2xl font-[600] flex justify-center items-center rounded-tl-[16px]	rounded-bl-[16px]	border-[#ACACAC] border'>
                    Project time
                  </div>
                  <Input
                    size='large'
                    min={1}
                    max={100}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.actual_time}
                    type='number'
                    name='actual_time'
                    className='w-[15rem] rounded-none border-x-0'
                  />
                  <div className='w-[10rem] h-[5rem] bg-[#ECECEC] font-2xl font-[600] flex justify-center items-center rounded-tr-[16px]	rounded-br-[16px] border-[#ACACAC] border'>
                    in years
                  </div>
                </div>
              </div>

              <div className='w-[30rem] mt-16' id='select-input'>
                <Select
                  className='w-[100%] h-[5rem] border-[#ACACAC]'
                  placeholder='Project status'
                  onChange={(value) => {
                    props.setFieldValue('status', value)
                  }}
                  value={props.values.status}
                  options={PROJECT_STATUS_OPTIONS}
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  )
}

export { Step1, Step2, Step3 }
