import React, { useCallback, useState } from 'react'
import { Modal, Input, Radio, Space } from 'antd'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '../../../firebase/firebase'
import { showError, showSuccess } from '../../../utils'

const EditModal = ({ show, onModalHandle, projectDetail, onFinalSave }) => {
  console.log('projectDetail', projectDetail)
  const [val, setVal] = useState({
    name: projectDetail.name,
    desc: projectDetail.desc,
    status: projectDetail.status,
    is_cost_updated: projectDetail.is_cost_updated,
    updated_cost: projectDetail.updated_cost,
  })

  const handleChange = useCallback((e) => {
    setVal((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }))
  }, [])

  const onSave = () => {
    //CHECK VALIDATIONS
    if (!val.name) return showError('Project Title is required!')
    if (!val.desc) return showError('Project Description is required!')

    if (val.is_cost_updated) {
      if (!val.updated_cost)
        return showError('Project Updated Cost is required!')
    }

    //CALL FIREBASE API
    const docRef = doc(db, 'projects', projectDetail.project_id)
    const data = {
      ...val,
      updated_cost: Number(val.updated_cost),
    }

    updateDoc(docRef, data)
      .then((docRef) => {
        showSuccess('Project updated successfully!')
        onFinalSave(data)
        onModalHandle(false)()
      })
      .catch((error) => {
        console.log(error)
        showError(error || 'Something went wrong!')
      })
  }

  return (
    <div className='edit-modal'>
      <Modal
        title=''
        open={show}
        closable={false}
        maskClosable={false}
        footer={null}
        style={{
          position: 'absolute',
          left: 72,
          right: 72,
          top: 72,
          bottom: 72,
          width: 'auto',
          // height: 'calc(100% - 544px)',
          // overflow: 'hidden',
        }}
        className='rounded-none'
        wrapClassName='rounded-none edit-modal'
        destroyOnClose={true}
        width='auto'
      >
        <div className=''>
          {/* HEADER BUTTONS */}
          <div className='flex flex-row justify-between items-center shadow-[0_4px_4px_rgba(0,0,0,0.07)] py-5 px-8'>
            <h3
              className='text-[24px] text-[#000000] font-[500] cursor-pointer'
              onClick={onModalHandle(false)}
            >
              Cancel
            </h3>
            <h3 className='text-[40px] text-[#000000] font-[600] cursor-pointer'>
              Edit Project Details
            </h3>
            <div
              onClick={onSave}
              className='w-[8rem] h-[2.5rem] rounded-3xl bg-[#FFCC33] cursor-pointer flex justify-center items-center'
            >
              <h3 className='font-[500] text-[#000] text-xl text-center'>
                Save
              </h3>
            </div>
          </div>
          {/*  BODY */}
          <div className='mt-8 pb-8 px-8'>
            <div className='flex flex-col gap-y-6'>
              <div>
                <h3 className='font-[600] text-[#000] text-[24px]'>
                  Project Title
                </h3>
                <Input
                  placeholder=''
                  type='text'
                  onChange={handleChange}
                  value={val['name']}
                  name='name'
                  className='w-[70%] text-[18px] pl-0 border-t-0 border-x-0 border-[#000000] rounded-none outline-none focus:outline-none focus:shadow-none focus:border-[#4096ff] focus:border-x-0 hover:border-[#4096ff] hover:border-x-0	'
                />
              </div>

              <div>
                <h3 className='font-[600] text-[#000] text-[24px]'>
                  Project Description
                </h3>
                <Input
                  placeholder=''
                  type='text'
                  onChange={handleChange}
                  value={val['desc']}
                  name='desc'
                  className='w-[70%] text-[18px] pl-0 border-t-0 border-x-0 border-[#000000] rounded-none outline-none focus:outline-none focus:shadow-none focus:border-[#4096ff] focus:border-x-0 hover:border-[#4096ff] hover:border-x-0	'
                />
              </div>

              <div>
                <h3 className='font-[600] text-[#000] text-[24px]'>
                  Project Status
                </h3>
                <Radio.Group
                  onChange={handleChange}
                  value={val['status']}
                  className='mt-4 font-[600] text-[20px] radio-group'
                  buttonStyle='solid'
                  size='large'
                  name='status'
                >
                  <Space direction='vertical'>
                    <Radio value='Awarded' className='text-[20px]'>
                      Awarded
                    </Radio>
                    <Radio value='In Progress' className='text-[20px]'>
                      In Progress
                    </Radio>
                    <Radio value='Completed' className='text-[20px]'>
                      Completed
                    </Radio>
                  </Space>
                </Radio.Group>
              </div>

              <div>
                <h3 className='font-[600] text-[#000] text-[24px]'>
                  Are there any change of scope in this project?
                </h3>
                <Radio.Group
                  onChange={handleChange}
                  value={val['is_cost_updated']}
                  className='mt-4 font-[600] text-[20px] radio-group'
                  buttonStyle='solid'
                  size='large'
                  name='is_cost_updated'
                  disabled={projectDetail.is_cost_updated}
                >
                  <Radio value={false} className='text-[20px]'>
                    No
                  </Radio>
                  <Radio value={true} className='text-[20px]'>
                    Yes
                  </Radio>
                </Radio.Group>
              </div>

              <div className='flex flex-row'>
                <div className='basis-1/2'>
                  <h3 className='font-[600] text-[#000] text-[24px]'>
                    Project Cost
                  </h3>
                  <h3 className='font-[600] text-[#9D9D9D] text-[20px]'>
                    {projectDetail.initial_cost + ' crores'}
                  </h3>
                </div>
                <div className='basis-1/2'>
                  {val.is_cost_updated && (
                    <>
                      <h3 className='font-[600] text-[#000] text-[24px]'>
                        Project Updated Cost
                      </h3>
                      <Input
                        placeholder=''
                        type='number'
                        onChange={handleChange}
                        value={val['updated_cost']}
                        name='updated_cost'
                        className='w-[70%] text-[18px] pl-0 border-t-0 border-x-0 border-[#000000] rounded-none outline-none focus:outline-none focus:shadow-none focus:border-[#4096ff] focus:border-x-0 hover:border-[#4096ff] hover:border-x-0	'
                      />
                    </>
                  )}
                </div>
              </div>

              <div className=''>
                <h3 className='font-[600] text-[#000] text-[24px]'>
                  Department
                </h3>
                <h3 className='font-[600] text-[#9D9D9D] text-[20px]'>
                  {projectDetail.department}
                </h3>
              </div>

              {/* <div>
                <h3 className='font-[600] text-[#000]'>
                  Assign color to this project
                </h3>
              </div> */}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default EditModal
