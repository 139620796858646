import React, { useState } from 'react'
import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import {
  getDoc,
  updateDoc,
  doc,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore'
import { db } from '../../../firebase/firebase'

const ProfileCard = ({ user }) => {
  const { is_viewed = false, is_shortlisted = false } = user
  const [isInterested, setIsInterested] = useState(is_shortlisted)

  const onViewedHandle = async() => {
    console.log('onViewedHandle', user)

    if (is_viewed) return // Don't call api if already is_viewed true
    // TODO:: Call api here to change is_viewed to true
    if (is_viewed) return; // Don't call API if already is_viewed true
    
    const resumeRef = doc(db, "new_resumes", user.resume_id);

    try {
        await updateDoc(resumeRef, {
            is_viewed: true
        });
    } catch (error) {
        console.error("Error updating document: ", error);
    }
  }

  const onInterestHandle = async() => {
    console.log('onInterestHandle', user)

    // TODO:: Call api here to change is_shortlisted
    setIsInterested(!isInterested)

    const resumeRef = doc(db, "new_resumes", user.resume_id);

    try {
        await updateDoc(resumeRef, {
            is_shortlisted: !isInterested
        });
    } catch (error) {
        console.error("Error updating document: ", error);
    }
  }

  return (
    <div className='border-[#E9EDF3] border-2 rounded-xl p-10'>
      <div className='flex flex-row justify-between'>
        <div className='flex flex-row items-center gap-2'>
          <h3 className='text-lg md:text-xl lg:text-3xl font-semibold text-rclBlack'>
            {user.name}
          </h3>
          {is_viewed && (
            <div className='w-[64px] h-[24px] border border-solid border-[#E8E8E8] rounded-lg flex justify-center items-center'>
              <h3 className='text-[12px] font-normal text-rclBlack'>Viewed</h3>
            </div>
          )}
        </div>
        <div className='flex flex-row items-center gap-6'>
          <a
            href={user.resume && user.resume}
            className='text-[#000]'
            target='_blank'
            onClick={onViewedHandle}
          >
            <div className='w-[11rem] h-[2.5rem] rounded-3xl bg-rclYellow cursor-pointer flex justify-center items-center hover:text-[#FFF] border-0 border-[#FFF] hover:border-2 hover:bg-[#FFF] hover:border-rclGrey transition-all duration-300'>
              <h3 className='font-medium text-rclBlack text-lg text-center'>
                Show Resume
              </h3>
            </div>
          </a>
          <div
            onClick={() => {
              onInterestHandle()
              onViewedHandle()
            }}
            className={`w-[11rem] h-[2.5rem] rounded-3xl ${
              isInterested ? 'bg-[#039800]' : 'bg-[#E8E8E8]'
            } cursor-pointer flex justify-center items-center`}
          >
            <h3
              className={`font-medium ${
                isInterested ? 'text-[#FFFFFF]' : ' text-[#6D6E6F]'
              } text-lg text-center`}
            >
              I am interested
            </h3>
          </div>
        </div>
      </div>
      <h3 className='font-[400] text-[#000] text-xl mt-2 mb-3 w-[75%]'>
        {user.message}
      </h3>
      <div className='flex flex-row'>
        <a href={`mailto:${user.email}`}>
          <div className='px-4 py-2 min-w-[11rem] h-[2.5rem] rounded-3xl bg-[#FAFCFF] border border-[#97019A] cursor-pointer flex justify-center items-center'>
            <MailOutlined style={{ color: '#97019A' }} />
            <h3 className='font-[400] text-xl text-[#97019A] text-center ml-2'>
              {user.email}
            </h3>
          </div>
        </a>
        <a href={`tel:${user.phone}`}>
          <div className='px-4 py-2 min-w-[11rem] h-[2.5rem] rounded-3xl bg-[#FAFCFF] border border-[#02AE66] cursor-pointer flex justify-center items-center ml-5'>
            <PhoneOutlined
              style={{ color: '#02AE66', transform: 'rotate(90deg)' }}
            />
            <h3 className='font-[400] text-xl text-[#02AE66] text-center ml-2'>
              {user.phone}
            </h3>
          </div>
        </a>
      </div>
    </div>
  )
}

export default ProfileCard
