import React from 'react'
import { useNavigate } from 'react-router-dom'
import { EnvironmentOutlined } from '@ant-design/icons'

const JobCard = ({ label, category, count = 0, id, location }) => {
  const navigate = useNavigate()

  return (
    <div
      className={`min-h-[180px] h-fit p-4 relative flex flex-row rounded-[16px] cursor-pointer border-2 border-rclGrey border-l-[12px] hover:bg-rclHoverBlueAlpha transition-all duration-400 hover:scale-105`}
      onClick={() => id && navigate(`/admin/job-detail/${id}`)}
    >
      {/* <div className='bg-[#E2E8F0] h-[100%] w-[16px] absolute inset-0 rounded-[8px]'></div> */}
      <div className='flex flex-col justify-center'>
        <h3 className='text-lg md:text-xl lg:text-2xl text-black font-semibold pr-6'>
          {label}
        </h3>
        <h3 className='text-sm md:text-base lg:text-lg text-rclBlue font-medium'>
          {category}
        </h3>
        {/* <div className='mt-2 flex flex-row items-center'>
          <EnvironmentOutlined  />
          <h3 className='text-xs md:text-sm lg:text-lg font-medium ml-1'>
            {location}
          </h3>
        </div> */}
      </div>
      {Boolean(count) && (
        <div className='w-9 h-9 absolute right-4 bg-rclGreenAlpha rounded-full  border-rclGreen border flex flex-row justify-center items-center self-center'>
          <h3 className='text-xs md:text-sm lg:text-lg text-black font-medium p-4'>
            {count}
          </h3>
        </div>
      )}
    </div>
  )
}

export default JobCard
