import React, { useEffect, useState } from 'react'
import { Input, Button, Form } from 'antd'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'

import { auth, logInWithEmailAndPassword, db } from '../../../firebase/firebase'
import { showError } from 'utils'

const Login = () => {
  const [user, loading, error] = useAuthState(auth)
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const onLoginHandle = async (user) => {
    setLoader(true)
    const { email, password } = user
    const resp = await logInWithEmailAndPassword(email, password)
    if (resp && !resp.status) {
      // showMsg(MSGS_TYPE.ERROR, resp.error)
      showError(resp.error)
    }
    setLoader(false)
  }

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return
    }

    if (user) navigate('/admin/dashboard')
  }, [user, loading])

  // const getUserInfo = async () => {
  //   const snapshot = await getDoc(doc(db, 'users', user.uid))
  //   const userInfo = snapshot.data()
  //   const { is_admin, role_name, role } = userInfo || {}
  //   console.log('snapshot', snapshot.data())

  //   if (role === 1) {
  //     navigate('/admin/dashboard')
  //   } else {
  //     navigate('/dashboard/images')
  //   }
  // }

  return (
    <div className="w-full mx-auto h-screen grid place-content-center bg-[url('/public/assets/bg-main.webp')] bg-no-repeat bg-center bg-cover relative">
      <h3 className='text-3xl md:text-4xl text-[#fff] font-[600] absolute top-10 left-10'>
        RCL Admin
      </h3>

      <div className='h-[31rem] w-[30rem] bg-[#fff] rounded-3xl px-10 py-8'>
        <h3 className='text-3xl font-semibold text-center'>User Login</h3>
        <h3 className='text-2xl font-medium text-center pt-5 mb-7'>
          Hey, enter your details to get sign in to your account
        </h3>
        <>
          <Form
            name='basic'
            initialValues={{ remember: true }}
            onFinish={onLoginHandle}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
          >
            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Please input your valid email!',
                  type: 'email',
                },
              ]}
            >
              <Input className='h-[3.2rem]' placeholder='Enter Email' />
            </Form.Item>

            <Form.Item
              name='password'
              rules={[
                { required: true, message: 'Please input your password!' },
              ]}
            >
              <Input.Password
                className='h-[3.2rem]'
                placeholder='Enter Password'
              />
            </Form.Item>

            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                className='w-[100%] h-[3.2rem] bg-[#3F41EC]'
                disabled={loader}
              >
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </>
      </div>
    </div>
  )
}

export default Login
