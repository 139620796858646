import React, { useState } from 'react'
import { Modal, Spin } from 'antd'

import useFireStore from '../../../hooks/useFireStore'
import useStorage from '../../../hooks/useStorage'
import { motion } from 'framer-motion'
import CloseIcon from 'icons/close-icon.svg'

const VideoGrid = ({
  onModalHandler,
  title,
  subTitle,
  project_id,
  showDeleteBtn,
}) => {
  const [alert, setAlert] = useState(false)
  const [show, setShow] = useState(false)
  const [dataToDelete, setDataToDelete] = useState(null)
  const [removing, setRemoving] = useState(false)

  const { data: videos } = useFireStore('videos', project_id)
  const { deleteFile } = useStorage()
  console.log('VIDEOS--', videos)

  const onDeleteReq = (data) => {
    setShow(true)
    setDataToDelete(data)
  }

  const onDelectConirm = async () => {
    setRemoving(true)
    const resp = await deleteFile(dataToDelete.url, dataToDelete.id, 'videos')
    setRemoving(false)
    setShow(false)
  }

  const onHide = () => {
    setShow(false)
    setDataToDelete(null)
  }

  return (
    <>
      <Modal
        title={title || `Delete video`}
        visible={show}
        onOk={onDelectConirm}
        onCancel={onHide}
        okText={removing ? 'Deleting..' : 'Yes'}
        okButtonProps={{ disabled: removing }}
        cancelButtonProps={{ disabled: removing }}
        centered
      >
        {removing ? (
          <>
            Please Wait... <Spin />
          </>
        ) : (
          subTitle || 'Are you sure you want to remove this entry'
        )}
      </Modal>

      <div className='grid grid-cols-2 auto-rows-[320px] gap-4 bg-[#fff] mt-6'>
        {videos &&
          videos.map((video) => (
            <motion.div
              key={video.video_id}
              whileHover={{ opacity: 1 }}
              layout
              className='relative group'
            >
              <div
                style={{ background: 'rgba(0, 0, 0, 0.3)' }}
                className='absolute inset-0 rounded-xl'
              ></div>

              <motion.video
                controls
                preload='auto'
                className='w-[100%] h-[100%] rounded-xl'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1 }}
              >
                <source
                  src={video.video_url}
                  //   type='video/mp4'
                />
              </motion.video>
              {showDeleteBtn && (
                <motion.img
                  src={CloseIcon}
                  className='z-20 absolute shadow-[0_4px_40px_rgba(0,0,0,0.07)] right-6 top-6 transition-all duration-500 cursor-pointer hidden group-hover:flex'
                  onClick={() => onDeleteReq(video)}
                />
              )}
            </motion.div>
          ))}
      </div>
    </>
  )
}
export default VideoGrid
