import { useEffect, useState } from 'react'

import { db, projectFireStore } from '../firebase/firebase'
import { collection, onSnapshot, where, query } from 'firebase/firestore'

const useFireStore = (collectionName, project_id) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   setLoading(true)
  //   const dbRef = collection(db, collectionName)

  //   onSnapshot(dbRef, (docsSnap) => {
  //     docsSnap.forEach((doc) => {
  //       console.log('DOC===', doc)
  //       setData((pre) => [...pre, doc.data()])
  //       setLoading(false)
  //     })
  //   })
  // }, [collectionName])

  // useEffect(() => {
  //   setLoading(true)

  //   const unsub = projectFireStore
  //     .collection('images')
  //     // .orderBy('created_at', 'desc')
  //     .onSnapshot((snap) => {
  //       let documents = []
  //       console.log('SNAP====', snap)
  //       snap.forEach((doc) => {
  //         console.log('doc====', doc)
  //         documents.push({ ...doc.data(), id: doc.id })
  //       })
  //       console.log('DOCUMENTS===', documents)
  //       setData(documents)
  //     })
  //   setLoading(false)

  //   return () => unsub()
  // }, [collectionName])

  useEffect(() => {
    setLoading(true)
    const q = query(
      collection(db, collectionName),
      where('project_id', '==', project_id)
    )
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const documents = []
      querySnapshot.forEach((doc) => {
        documents.push({ ...doc.data(), id: doc.id })
      })
      setData(documents)
    })
    setLoading(false)

    return () => unsubscribe()
  }, [collectionName])

  return { data, loading }
}

export default useFireStore
