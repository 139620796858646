import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'

import { auth } from '../../firebase/firebase'

const PrivateRoute = ({ children }) => {
  const [user, loading, error] = useAuthState(auth)
  const navigate = useNavigate()

  console.log('USER', user, loading)
  //   if (loading) return <></>

  return user ? children : navigate('/')
}

export default PrivateRoute
