import React, { useCallback, useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getDoc, doc } from 'firebase/firestore'
import {
  ArrowLeftOutlined,
  FileImageOutlined,
  PlayCircleOutlined,
  FileTextOutlined,
} from '@ant-design/icons'
import { useSearchParams } from 'react-router-dom'

import { db, projectFireStore } from '../../../firebase/firebase'
import { Card, Tags } from '../components/'
import ImageGrid from './image-grid'
import { ProgressBar, Header } from 'components'
import { UserContext } from '../../../context'
import TagView from './tag-view'
import DocumentView from './document-view'
import {
  checkCareerRole,
  checkSuperAdminRole,
  checkAdminRole,
  checkManagerRole,
  checkProjectDirectorRole,
  checkProjectManagerRole,
  checkSiteEngineerRole,
} from 'utils'

const IMAGE_TYPES = ['image/png', 'image/jpeg']
const VIDEO_TYPES = ['video/mp4', 'video/x-m4v']
const TAGS = ['Images', 'Videos', 'Documents']

const ProjectView = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const userInfo = useContext(UserContext)
  let [searchParams, setSearchParams] = useSearchParams()

  const [activeTag, setActiveTag] = useState('Images')
  const [projectDetail, setProjectDetail] = useState()
  const [loading, setLoading] = useState(true)
  const [file, setFile] = useState(null)
  const [error, setError] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)

  const isSuperAdmin = checkSuperAdminRole(userInfo.role)
  const isAdmin = checkAdminRole(userInfo.role)
  const isManager = checkManagerRole(userInfo.role)
  const isProjectDirector = checkProjectDirectorRole(userInfo.role)
  const isProjectManager = checkProjectManagerRole(userInfo.role)
  const isSiteEngineer = checkSiteEngineerRole(userInfo.role)
  const isCareerManager = checkCareerRole(userInfo.role)

  useEffect(() => {
    if (!id) return
    getProjectDetailById()
    // getUsersDetails()
    getInitialTag()
  }, [id])

  const getInitialTag = () => {
    const tag = searchParams.get('view')
    if (TAGS.includes(tag)) {
      setActiveTag(tag)
    }
  }

  const getProjectDetailById = async () => {
    const docRef = doc(db, 'projects', id)

    try {
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        setProjectDetail(docSnap.data())
      } else {
        console.log('Document does not exist')
      }
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }

  const onClickTag = useCallback((tag) => {
    setActiveTag(tag)
    setSearchParams({ view: tag })
    setError(null)
  }, [])

  const onUpload = (event) => {
    let selectedFile = event.target.files[0]

    let TYPES = activeTag === 'Images' ? IMAGE_TYPES : VIDEO_TYPES
    let ERROR_MSG =
      activeTag === 'Images'
        ? 'Please select a image file (png or jpeg)'
        : 'Please select a video file (mp4 or x-m4v)'

    if (selectedFile && TYPES.includes(selectedFile.type)) {
      setFile(selectedFile)
      setError('')
    } else {
      setFile(null)
      setError(ERROR_MSG)
    }
  }

  const showBtnHandle = () => {
    if (
      isSuperAdmin ||
      isAdmin ||
      isProjectDirector ||
      isProjectManager ||
      isSiteEngineer
    )
      return true
    return false
  }

  const showDocBtnHandle = () => {
    if (isSuperAdmin || isAdmin || isProjectDirector || isProjectManager)
      return true
    return false
  }

  if (loading) return <></>

  return (
    <>
      {/* HEADER */}
      <div className='shadow-[0px_1px_8px_rgba(0,0,0,0.07)] py-3 px-1 mb-8'>
        <div
          className='flex flex-row  items-center cursor-pointer mb-4 transition-all duration-500 hover:bg-[#E8ECF4] w-fit px-4 py-2 rounded-[200px]'
          onClick={() => navigate(`/admin/project-detail/${id}`)}
        >
          <ArrowLeftOutlined size={25} style={{ color: '#000' }} />
          <h3 className='text-xl font-[600] ml-2 text-[#000] inline-block'>
            {projectDetail.name}
          </h3>
        </div>
        <div className='px-6'>
          <h3 className='text-[40px] text-[#000] font-[600]'>
            Project Infometrics
          </h3>

          <div className='mt-6 mb-[2rem]'>
            {/* TAGS */}
            <Tags tags={TAGS} activeTag={activeTag} onClickTag={onClickTag} />
          </div>
        </div>
      </div>

      {/* BODY ACCORDING TAG */}
      {activeTag === 'Images' && (
        <TagView
          icon={
            <FileImageOutlined style={{ fontSize: '30px', color: '#4D4D4D' }} />
          }
          title='Add Images'
          accept='image/png, image/jpeg'
          file={file}
          setFile={setFile}
          info={{ project_id: id, creator_id: userInfo.user_id }}
          error={error}
          projectId={id}
          onUpload={onUpload}
          type='images'
          showAddBtn={showBtnHandle()}
          showDeleteBtn={showBtnHandle()}
        />
      )}

      {activeTag === 'Videos' && (
        <TagView
          icon={
            <PlayCircleOutlined
              style={{ fontSize: '30px', color: '#4D4D4D' }}
            />
          }
          title='Add Videos'
          accept='video/mp4,video/x-m4v,video/*'
          file={file}
          setFile={setFile}
          info={{ project_id: id, creator_id: userInfo.user_id }}
          error={error}
          projectId={id}
          onUpload={onUpload}
          type='videos'
          bgColor='#FFF2EC'
          showAddBtn={showBtnHandle()}
          showDeleteBtn={showBtnHandle()}
        />
      )}

      {activeTag === 'Documents' && (
        <DocumentView
          icon={
            <FileTextOutlined style={{ fontSize: '30px', color: '#4D4D4D' }} />
          }
          title='Add Document'
          accept='application/pdf'
          file={file}
          setFile={setFile}
          info={{ project_id: id, creator_id: userInfo.user_id }}
          error={error}
          projectId={id}
          onUpload={onUpload}
          type='documents'
          bgColor='#FFF2EC'
          showAddBtn={showDocBtnHandle()}
          showDeleteBtn={showDocBtnHandle()}
        />
      )}
    </>
  )
}

export default ProjectView
