import React from 'react'

const Tag = ({ tag, activeTag, onClickTag }) => {
  return (
    <div
      onClick={() => onClickTag(tag)}
      className={`${
        activeTag === tag ? 'bg-rclActive' : 'bg-rclGrey'
      }   flex justify-center items-center px-6 py-2 rounded-[10rem] cursor-pointer`}
    >
      <h3
        className={`${activeTag === tag ? 'text-[#fff]' : 'text-rclActiveAlpha'}
  text-[24px] font-[500]`}
      >
        {tag}
      </h3>
    </div>
  )
}

export default Tag
