import React, { useCallback, useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { PlusOutlined } from '@ant-design/icons'
import { Header, Tags } from '../components/'
import JobCard from './job-card'
import { projectFireStore } from '../../../firebase/firebase'
import { UserContext } from '../../../context'
import { checkCareerRole } from 'utils'

const Jobs = () => {
  const [jobs, setJobs] = useState([])
  const [activeTag, setActiveTag] = useState('All Careers')
  const navigate = useNavigate()

  const userInfo = useContext(UserContext)
  const isCareerManager = checkCareerRole(userInfo.role)

  useEffect(() => {
    getJobs()
  }, [])

  const getJobs = async () => {
    const snapshot = await projectFireStore.collection('jobs').get()
    let jobs = snapshot.docs
      .map((doc) => doc.data())
      .filter((job) => job.designation_opening != 0)

    const snapshotUsers = await projectFireStore.collection('new_resumes').get()
    //GET THOSE USERS WHO HAVE RESUME AND CAREERE ID
    let users = snapshotUsers.docs
      .map((doc) => doc.data())
      .filter((user) => user.resume && user.job_id)

    let newJobs = [...jobs]

    users.map((user) => {
      let jobExist = jobs.find((job) => job.job_id === user.job_id)
      if (jobExist) {
        let jobIndex = jobs.findIndex((job) => job.job_id === user.job_id)

        newJobs.splice(jobIndex, 1, {
          ...newJobs[jobIndex],
          resume_count: newJobs[jobIndex].resume_count
            ? newJobs[jobIndex].resume_count + 1
            : 1,
        })
      }
    })

    setJobs(newJobs)
  }

  console.log('jobs-->>>', jobs)

  const onClickTag = useCallback((tag) => {
    setActiveTag(tag)

    //HERE FETCH JOBS FROM BACKEND LATER
    if (tag === 'Electrical') setJobs([])
    else {
      getJobs()
    }
  }, [])

  let plusButton = (
    <div
      onClick={() => navigate('/admin/jobs/add')}
      className='w-[72px] h-[72px] rounded-[43px] border-2 border-rclGrey flex justify-center items-center hover:bg-rclGrey cursor-pointer'
    >
      <PlusOutlined style={{ color: '#000', fontSize: '25px' }} />
    </div>
  )

  return (
    <div className=''>
      <div className='flex flex-row justify-between items-center'>
        <h3 className='text-[56px] text-[#000] font-[600]'>Careers at RCL</h3>

        {!isCareerManager && plusButton}
      </div>
      <div className='mt-12 mb-[2rem]'>
        {/* TAGS */}
        <Tags
          tags={['All Careers', 'Road & Highways', 'Electrical']}
          activeTag={activeTag}
          onClickTag={onClickTag}
        />
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
        {jobs.map((job) => (
          <JobCard
            label={job.name}
            key={job.job_id}
            count={job.resume_count}
            id={job.job_id}
            location={job?.locations[0]?.state}
			category={job?.category}
          />
        ))}
      </div>
    </div>
  )
}

export default Jobs
