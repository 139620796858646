import { CAREER_MENU, MENU, PROJECT_MENU } from './menu'

const getMenu = (
  isSuperAdmin,
  isAdmin,
  isManager,
  isProjectDirector,
  isProjectManager,
  isSiteEngineer,
  isCareerManager
) => {
  if (isSuperAdmin) return MENU
  else if (isAdmin) return MENU
  else if (isManager) return MENU
  else if (isProjectDirector) return PROJECT_MENU
  else if (isProjectManager) return PROJECT_MENU
  else if (isSiteEngineer) return PROJECT_MENU
  else if (isCareerManager) return CAREER_MENU
  else {
    return CAREER_MENU
  }
}

export { getMenu }
