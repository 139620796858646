import React from 'react'

import { ProgressBar } from 'components'
import ImageGrid from './image-grid'
import VideoGrid from './video-grid'

const TagView = ({
  icon,
  title,
  onUpload,
  accept,
  file,
  info,
  projectId,
  error,
  setFile,
  type,
  bgColor = '#ECFBFF',
  showAddBtn,
  showDeleteBtn,
}) => {
  let btn = (
    <div
      style={{ background: bgColor }}
      className='relative rounded-3xl flex flex-row justify-center items-center py-4 cursor-pointer'
    >
      {icon && icon}
      <h3 className='text-[32px] text-[#4D4D4D] font-[700] ml-3'>{title}</h3>
      <input
        type='file'
        onChange={onUpload}
        className='inset-0 opacity-0 absolute cursor-pointer'
        accept={accept}
      />
    </div>
  )
  return (
    <div>
      {showAddBtn && btn}

      <div>
        {file && (
          <ProgressBar file={file} info={info} setFile={setFile} type={type} />
        )}

        {type === 'images' && (
          <ImageGrid
            onModalHandler={() => {}}
            project_id={projectId}
            showDeleteBtn={showDeleteBtn}
          />
        )}

        {type === 'videos' && (
          <VideoGrid
            onModalHandler={() => {}}
            project_id={projectId}
            showDeleteBtn={showDeleteBtn}
          />
        )}

        {error && <div className='error'>{error}</div>}
      </div>
    </div>
  )
}

export default TagView
