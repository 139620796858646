import React from 'react'

const AdminDashBoard = () => {
  return (
    <div className=''>
      <div className='bg-[#F4FEEF] p-10 rounded-[2rem]'>
        <h3 className='text-[#4D4D4D] font-[700] text-[2rem]'>Top Sectors</h3>
        <div className='flex flex-row gap-10 mt-4'>
          <div className='h-[200px] flex justify-center items-center w-[50%] bg-[#fff] rounded-[2rem] shadow-[0_4px_40px_rgba(0,0,0,0.07)] cursor-pointer'>
            <h3 className='text-[#000] font-[700] text-[2rem]'>
              Road Construction
            </h3>
          </div>
          <div className='h-[200px] flex justify-center items-center w-[50%] bg-[#fff] rounded-[2rem] shadow-[0_4px_40px_rgba(0,0,0,0.07)] cursor-pointer'>
            <h3 className='text-[#000] font-[700] text-[2rem]'>Electrical</h3>
          </div>
        </div>
      </div>

      <div className='flex flex-row gap-10 mt-8'>
        <div className='h-[200px] px-10 flex flex-col justify-center w-[50%] bg-[#F4F2FF] rounded-[2rem]'>
          <h3 className='text-[#4D4D4D] font-[700] text-[4rem]'>17</h3>
          <h3 className='text-[#4D4D4D] font-[700] text-[2rem] mt-[-22px]'>
            Completed Projects
          </h3>
        </div>
        <div className='h-[200px] px-10 flex flex-col justify-center w-[50%] bg-[#FFF2EC] rounded-[2rem]'>
          <h3 className='text-[#4D4D4D] font-[700] text-[4rem]'>3</h3>
          <h3 className='text-[#4D4D4D] font-[700] text-[2rem] mt-[-22px]'>
            On Going Projects
          </h3>
        </div>
      </div>
    </div>
  )
}

export default AdminDashBoard
