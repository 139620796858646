import { useState, useEffect } from 'react'
import { ref, deleteObject } from 'firebase/storage'
import { doc, deleteDoc, collection, setDoc } from 'firebase/firestore'

import {
  projectStorage,
  projectFireStore,
  timeStamp,
  db,
} from '../firebase/firebase'

const useStorage = (file, type, info) => {
  const [progress, setProgress] = useState(0)
  const [error, setError] = useState(null)
  const [url, setUrl] = useState(null)

  useEffect(() => {
    console.log('USE EFFCET Run---')
    if (!file) return

    addFile()
  }, [file])

  const addFile = () => {
    const storageRef = projectStorage.ref(`/${type}/${file.name}`)

    const fireStoreRef = projectFireStore.collection(type) //type here collection name in firestore
    const newDocRef = doc(collection(db, type)) // Add a new document with a generated id

    storageRef.put(file).on(
      'state_changed',
      (snap) => {
        let progress = (snap.bytesTransferred / snap.totalBytes) * 100
        console.log('PROGRESS--', progress)
        setProgress(progress)
      },
      (err) => {
        console.log('ERRO--', err)
        setError(err)
      },
      async () => {
        const url = await storageRef.getDownloadURL()
        console.log('URL--', url)
        setUrl(url)
        const createdAtTime = timeStamp()
        const docId = newDocRef.id //GET ID

        // const resp = await fireStoreRef.add({
        //   created_at: createdAtTime,
        //   creator_id: info.creator_id,
        //   image_url: url,
        //   project_id: info.project_id,
        //   image_id: fireStoreRef.id,
        // })

        if (type === 'images') {
          await setDoc(newDocRef, {
            created_at: createdAtTime,
            creator_id: info.creator_id,
            image_url: url,
            project_id: info.project_id,
            image_id: docId,
          })
        } else if (type === 'videos') {
          await setDoc(newDocRef, {
            created_at: createdAtTime,
            creator_id: info.creator_id,
            video_url: url,
            project_id: info.project_id,
            video_id: docId,
          })
        } else {
          await setDoc(newDocRef, {
            created_at: createdAtTime,
            creator_id: info.creator_id,
            document_url: url,
            project_id: info.project_id,
            document_id: docId,
            title: info.title,
            status: info.status,
            color: info.color,
            tags: info.tags || [],
          })
        }
      }
    )
  }

  const deleteFile = async (path, id, type) => {
    const docRef = doc(projectFireStore, type, id)
    const desertRef = ref(projectStorage, path)

    //FIRST DELETE IMG URL FROM FIRESTORE
    deleteDoc(docRef)
      .then(() => {
        console.log('Entire Document has been deleted successfully.')

        // Delete the file
        deleteObject(desertRef)
          .then(() => {
            // File deleted successfully
            console.log('DELTED-----')
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
            console.log('ERROR---', error)
          })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return { progress, error, url, deleteFile }
}

export default useStorage
