import React, { useState, useEffect, useMemo } from 'react'

import { Route, Routes } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { getDoc, doc } from 'firebase/firestore'

import { Login, Admin } from './container'
import { PrivateRoute } from './components'
import { auth, db } from './firebase/firebase'
import { UserContext } from './context'
import {
  checkCareerRole,
  checkSuperAdminRole,
  checkAdminRole,
  checkManagerRole,
  checkProjectDirectorRole,
  checkProjectManagerRole,
  checkSiteEngineerRole,
  getRoutes,
} from 'utils'

function App() {
  const [user, loading, error] = useAuthState(auth)
  const [userInfo, setUserInfo] = useState({})
  const navigate = useNavigate()

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return
    }
    if (user) {
      getUserInfo()
    } else {
      navigate('/')
      setUserInfo({})
      //Al
    }
    // if (user) navigate('/dashboard/images')
  }, [user, loading])

  const getUserInfo = async () => {
    const snapshot = await getDoc(doc(db, 'users', user.uid)) //GET LOGGED IN USER DETAIL
    const userDetail = snapshot.data()

    console.log('USER DETAIL--', userDetail)
    setUserInfo(userDetail)
    const { is_admin, role_name, role } = userDetail || {}
    // if (role === 1) {
    // navigate('/admin/dashboard')
    // }
  }

  const isSuperAdmin = checkSuperAdminRole(userInfo.role)
  const isAdmin = checkAdminRole(userInfo.role)
  const isManager = checkManagerRole(userInfo.role)
  const isProjectDirector = checkProjectDirectorRole(userInfo.role)
  const isProjectManager = checkProjectManagerRole(userInfo.role)
  const isSiteEngineer = checkSiteEngineerRole(userInfo.role)
  const isCareerManager = checkCareerRole(userInfo.role)

  return (
    <div className='App'>
      <UserContext.Provider value={userInfo}>
        <Routes>
          {/* LOGIN ROUTE */}
          <Route path='/' element={<Login />} />

          {/* ROUTE FOR ADMIN */}
          <Route path='/admin' element={<Admin />}>
            {getRoutes(
              isSuperAdmin,
              isAdmin,
              isManager,
              isProjectDirector,
              isProjectManager,
              isSiteEngineer,
              isCareerManager
            )['outletRoutes'].map((route) => (
              <React.Fragment key={route.path}>
                <Route
                  path={route.path}
                  element={<PrivateRoute>{route.element()}</PrivateRoute>}
                />
              </React.Fragment>
            ))}
          </Route>
          <Route path='/admin'>
            {getRoutes(
              isSuperAdmin,
              isAdmin,
              isManager,
              isProjectDirector,
              isProjectManager,
              isSiteEngineer,
              isCareerManager
            )['routes'].map((route) => (
              <React.Fragment key={route.path}>
                <Route
                  path={route.path}
                  element={<PrivateRoute>{route.element()}</PrivateRoute>}
                />
              </React.Fragment>
            ))}
          </Route>
        </Routes>
      </UserContext.Provider>
    </div>
  )
}

export default App
