import React from 'react'

import Stepper from './stepper'

const AdminAddJob = () => {
  return (
    <>
      <Stepper />
    </>
  )
}

export default AdminAddJob
