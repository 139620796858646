import React, { useCallback, useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import {
  getDoc,
  doc,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore'
import { EditOutlined } from '@ant-design/icons'

import { Header } from '../components/'
import ProfileCard from './profile-card'
import { db } from '../../../firebase/firebase'
import EditModal from './edit-modal'
import { UserContext } from '../../../context'
import { checkCareerRole, checkSuperAdminRole, checkAdminRole } from 'utils'

const RESUME_URL =
  'https://firebasestorage.googleapis.com/v0/b/raj-corporation-limited.appspot.com/o/resumes%2F1673856174806CVisrarAhmad.pdf?alt=media&token=729a4e58-00bc-4168-9799-176f6e94e4ae%27'

const AdminJobDetail = () => {
  const { id } = useParams()
  const [jobDetail, setJobDetail] = useState()
  const [loading, setLoading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [appliedUsers, setAppliedUsers] = useState([])
  const [show, setShow] = useState(false)
  const userInfo = useContext(UserContext)

  const isSuperAdmin = checkSuperAdminRole(userInfo.role)
  const isAdmin = checkAdminRole(userInfo.role)
  const isCareerManager = checkCareerRole(userInfo.role)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  useEffect(() => {
    if (!id) return
    getJobDetailById()
    getUsersDetails()
  }, [id])

  const getJobDetailById = async () => {
    // setLoading(true)
    const docRef = doc(db, 'jobs', id)

    try {
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        setJobDetail(docSnap.data())
      } else {
        console.log('Document does not exist')
      }
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }

  const getUsersDetails = async () => {
    const q = query(collection(db, 'new_resumes'), where('job_id', '==', id))

    const querySnapshot = await getDocs(q)
    let users = querySnapshot.docs
      .map((doc) => doc.data())
      .filter((user) => user.resume) //GET THOSE USERS WHO HAVE RESUME
    setAppliedUsers(users)
  }

  const onModalHandle = useCallback(
    (val) => () => {
      setShow(val)
    },
    []
  )

  const onFinalSave = useCallback((data) => {
    setJobDetail((pre) => ({ ...pre, ...data }))
  }, [])

  const showBtnHandle = () => {
    if (isSuperAdmin || isAdmin) return editButton
    else <></>
  }

  let editButton = (
    <div
      onClick={onModalHandle(true)}
      className='bg-[#FAFCFF] px-3 py-1 border border-[#E5EAF2] rounded-2xl flex justify-center items-center cursor-pointer'
    >
      <EditOutlined />
      <h3 className='text-[1rem] font-[600] ml-2 text-rclBlack'>Edit</h3>
    </div>
  )

  if (loading) return <></>

  return (
    <>
      {/* Edit Modal */}
      {show && (
        <EditModal
          show={show}
          onModalHandle={onModalHandle}
          jobDetail={jobDetail}
          onFinalSave={onFinalSave}
        />
      )}

      <div>
        {/* Header */}
        <Header showBack={true} label='Job Detail' backLink='/admin/jobs' />
        {/* Bottom Content */}
        <div className='w-full mx-auto px-20 py-16'>
          <div className='flex flex-row items-center justify-between'>
            <h3 className='text-4xl md:text-5xl lg:text-7xl text-rclYellow font-semibold'>
              {jobDetail.category}
            </h3>
            {showBtnHandle()}
          </div>

          <div className='bg-[#FAFCFF] border-2 border-[#E2E8F0] mt-8 mb-16 p-10 rounded-2xl'>
            <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
              <div className='basis-1/2'>
                <h3 className='text-xs md:text-sm lg:text-lg font-semibold text-rclBlack'>
                  Designation
                </h3>
                <h3 className='text-xs md:text-sm lg:text-lg font-medium text-rclBlack'>
                  {jobDetail.name}
                </h3>
              </div>
              <div>
                <h3 className='text-xs md:text-sm lg:text-lg font-semibold text-rclBlack'>
                  Openings
                </h3>
                <h3 className='text-xs md:text-sm lg:text-lg font-medium text-rclBlack'>
                  {jobDetail.department_opening}
                </h3>
              </div>
              <div className='basis-1/2'>
                <h3 className='text-xs md:text-sm lg:text-lg font-semibold text-rclBlack'>
                  Education
                </h3>
                <h3 className='text-xs md:text-sm lg:text-lg font-normal text-rclBlack'>
                  {jobDetail.educations[0].name}
                </h3>
              </div>
              <div className='basis-1/2'>
                <h3 className='text-xs md:text-sm lg:text-lg font-semibold text-rclBlack'>
                  Experience
                </h3>
                <h3 className='text-xs md:text-sm lg:text-lg font-normal text-rclBlack'>
                  {jobDetail.educations[0].experience} Years and above
                </h3>
              </div>
              <div className='basis-1/2'>
                <h3 className='text-xs md:text-sm lg:text-lg font-semibold text-rclBlack'>
                  Description
                </h3>
                <h3 className='text-xs md:text-sm lg:text-lg font-normal text-rclBlack'>
                  {jobDetail.description}
                </h3>
              </div>
            </div>
          </div>

          <div className='mb-8'>
            <h3 className='text-lg md:text-2xl lg:text-4xl font-semibold text-rclBlack'>
              {!appliedUsers.length && 'No'} Resume
              {/* <span className='text-xl font-[400] ml-2'>(1/2)</span> */}
            </h3>
            <h3 className='text-lg md:text-xl lg:text-2xl text-rclBlack'>
              for {jobDetail.name}
            </h3>
          </div>

          <div className='flex flex-col gap-y-8'>
            {appliedUsers.map((user) => (
              <ProfileCard key={user.resume_id} user={user} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminJobDetail
